import React from "react"   
import ScrollTop from '../../ScrollTop/ScrollTop'
import BasicAccordion from '../../Accordion/Accordion'
import './Asset.css';


const AssetCatalog = () => {
    return (
       <div className="back">
           <ScrollTop/>
           
           <BasicAccordion/>
           
       </div>
    )
}

export default AssetCatalog