import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cardData from '../cardData/cardData'
import MediaCard from '../MediaCard/MediaCard';
import './Accordion.css';

export default function BasicAccordion() {
  return (
    <div className='Basic-Accordion'>
      <div className='div1'>
        <div className='cor-heard'>CIÊNCIAS – 1º ANO</div>
        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF01CI01  | (5 - Assets)   </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF01CI01) - Comparar características de diferentes materiais presentes em objetos de uso cotidiano, discutindo sua origem, os modos como são descartados e como podem ser usados de forma mais consciente.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[0]} />
              <MediaCard cardData={cardData[1]} />
              <MediaCard cardData={cardData[2]} />
              <MediaCard cardData={cardData[3]} />
              <MediaCard cardData={cardData[4]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header" >
            <div className='container' Typography>Ciências - EF01CI02 | (8 - Assets) </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF01CI02)  - Localizar, nomear e representar graficamente (por meio de desenhos) partes do corpo humano e explicar suas funções.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[5]} />
              <MediaCard cardData={cardData[6]} />
              <MediaCard cardData={cardData[7]} />
              <MediaCard cardData={cardData[8]} />
              <MediaCard cardData={cardData[9]} />
              <MediaCard cardData={cardData[10]} />
              <MediaCard cardData={cardData[11]} />
              <MediaCard cardData={cardData[12]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF01CI03 | (9 - Assets) </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF01CI03) - Discutir as razões pelas quais os hábitos de higiene do corpo (lavar as mãos antes de comer, escovar os dentes, limpar os olhos, o nariz e as orelhas etc.) são necessários para a manutenção da saúde.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[13]} />
              <MediaCard cardData={cardData[14]} />
              <MediaCard cardData={cardData[15]} />
              <MediaCard cardData={cardData[16]} />
              <MediaCard cardData={cardData[17]} />
              <MediaCard cardData={cardData[18]} />
              <MediaCard cardData={cardData[19]} />
              <MediaCard cardData={cardData[20]} />
              <MediaCard cardData={cardData[21]} />


            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF01CI04 | (1 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF01CI04) - Comparar características físicas entre os colegas, reconhecendo a diversidade e a importância da valorização, do acolhimento e do respeito às diferenças.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[22]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF01CI05 | (2 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF01CI05)- Identificar e nomear diferentes escalas de tempo: os períodos diários (manhã, tarde, noite) e a sucessão de dias, semanas, meses e anos.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[23]} />
              <MediaCard cardData={cardData[24]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF01CI06 | (2 - Assets) </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF01CI06) Selecionar exemplos de como a sucessão de dias e noites orienta o ritmo de atividades diárias de seres humanos e de outros seres vivos.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[25]} />
              <MediaCard cardData={cardData[26]} />

            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className='div2'>
        <div className='cor-heard'>CIÊNCIAS – 2º ANO</div>
        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF02CI01 | (2 - Assets) </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF02CI01) Identificar de que materiais (metais, madeira, vidro etc.) são feitos os objetos que fazem parte da vida cotidiana, como esses objetos são utilizados e com quais materiais eram produzidos no passado.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[27]} />
              <MediaCard cardData={cardData[28]} />

            </div>
          </AccordionDetails>
        </Accordion>



        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF02CI02 | (2 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF02CI02) Propor o uso de diferentes materiais para a construção de objetos de uso cotidiano, tendo em vista algumas propriedades desses materiais (flexibilidade, dureza, transparência etc.).
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[29]} />
              <MediaCard cardData={cardData[30]} />

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF02CI03 | (2 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF02CI03) Discutir os cuidados necessários à prevenção de acidentes domésticos (objetos cortantes e inflamáveis, eletricidade, produtos de limpeza, medicamentos etc.).
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[31]} />
              <MediaCard cardData={cardData[32]} />

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">

            <div className='container' Typography>Ciências - EF02CI04, EF02CI05 e EF02CI06 | (20 - Assets)</div>

          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>(EF02CI04) Descrever características de plantas e animais (tamanho, forma, cor, fase da vida, local onde se desenvolvem etc.) que fazem parte de seu cotidiano e relacioná-las ao ambiente em que eles vivem.</p>
              <p>(EF02CI05) Investigar a importância da água e da luz para a manutenção da vida de plantas em geral.</p>
              <p>(EF02CI06) Identificar as principais partes de uma planta (raiz, caule, folhas, flores e frutos) e a função desempenhada por cada uma delas, e analisar as relações entre as plantas, o ambiente e os demais seres vivos.</p>
            </div>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[33]} />
              <MediaCard cardData={cardData[34]} />
              <MediaCard cardData={cardData[35]} />
              <MediaCard cardData={cardData[36]} />
              <MediaCard cardData={cardData[37]} />
              <MediaCard cardData={cardData[38]} />
              <MediaCard cardData={cardData[39]} />
              <MediaCard cardData={cardData[40]} />
              <MediaCard cardData={cardData[41]} />
              <MediaCard cardData={cardData[42]} />
              <MediaCard cardData={cardData[43]} />
              <MediaCard cardData={cardData[44]} />
              <MediaCard cardData={cardData[45]} />
              <MediaCard cardData={cardData[46]} />
              <MediaCard cardData={cardData[47]} />
              <MediaCard cardData={cardData[48]} />
              <MediaCard cardData={cardData[49]} />
              <MediaCard cardData={cardData[50]} />
              <MediaCard cardData={cardData[51]} />
              <MediaCard cardData={cardData[52]} />

            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF02CI07 | (2 - Assets) </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF02CI07) Descrever as posições do Sol em diversos horários do dia e associá-las ao tamanho da sombra projetada.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[53]} />
              <MediaCard cardData={cardData[54]} />

            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF02CI08 | (2 - Assets) </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF02CI08) - Comparar o efeito da radiação solar (aquecimento e reflexão) em diferentes tipos de superfície (água, areia, solo, superfícies escura, clara e metálica etc.).
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[55]} />
              <MediaCard cardData={cardData[56]} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className='div3'>
        <div className='cor-heard'>CIÊNCIAS – 3º ANO</div>
        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI01 | (7 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI01) - Produzir diferentes sons a partir da vibração de variados objetos e identificar variáveis que influem nesse fenômeno.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[57]} />
              <MediaCard cardData={cardData[58]} />
              <MediaCard cardData={cardData[59]} />
              <MediaCard cardData={cardData[60]} />
              <MediaCard cardData={cardData[61]} />
              <MediaCard cardData={cardData[62]} />
              <MediaCard cardData={cardData[63]} />

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI02 | (4 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI02) - Experimentar e relatar o que ocorre com a passagem da luz através de objetos transparentes (copos, janelas de vidro, lentes, prismas, água etc.), no contato com superfícies polidas (espelhos) e na intersecção com objetos opacos (paredes, pratos, pessoas e outros objetos de uso cotidiano).
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[64]} />
              <MediaCard cardData={cardData[65]} />
              <MediaCard cardData={cardData[66]} />
              <MediaCard cardData={cardData[67]} />

            </div>
          </AccordionDetails>
        </Accordion>



        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI03 | (4 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI03) - Discutir hábitos necessários para a manutenção da saúde auditiva e visual considerando as condições do ambiente em termos de som e luz.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[68]} />
              <MediaCard cardData={cardData[69]} />
              <MediaCard cardData={cardData[70]} />
              <MediaCard cardData={cardData[71]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI04 | (11 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI04) - Identificar características sobre o modo de vida (o que comem, como se reproduzem, como se deslocam etc.) dos animais mais comuns no ambiente próximo.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[72]} />
              <MediaCard cardData={cardData[73]} />
              <MediaCard cardData={cardData[74]} />
              <MediaCard cardData={cardData[75]} />
              <MediaCard cardData={cardData[76]} />
              <MediaCard cardData={cardData[77]} />
              <MediaCard cardData={cardData[78]} />
              <MediaCard cardData={cardData[79]} />
              <MediaCard cardData={cardData[80]} />
              <MediaCard cardData={cardData[81]} />
              <MediaCard cardData={cardData[82]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI05 | (5 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI05) Descrever e comunicar as alterações que ocorrem desde o nascimento em animais de diferentes meios terrestres ou aquáticos, inclusive o homem.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[83]} />
              <MediaCard cardData={cardData[84]} />
              <MediaCard cardData={cardData[85]} />
              <MediaCard cardData={cardData[86]} />
              <MediaCard cardData={cardData[87]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI06 | (15 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI06) - Comparar alguns animais e organizar grupos com base em características externas comuns (presença de penas, pelos, escamas, bico, garras, antenas, patas etc.).

            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[88]} />
              <MediaCard cardData={cardData[89]} />
              <MediaCard cardData={cardData[90]} />
              <MediaCard cardData={cardData[91]} />
              <MediaCard cardData={cardData[92]} />
              <MediaCard cardData={cardData[93]} />
              <MediaCard cardData={cardData[94]} />
              <MediaCard cardData={cardData[95]} />
              <MediaCard cardData={cardData[96]} />
              <MediaCard cardData={cardData[97]} />
              <MediaCard cardData={cardData[98]} />
              <MediaCard cardData={cardData[99]} />
              <MediaCard cardData={cardData[100]} />
              <MediaCard cardData={cardData[101]} />
              <MediaCard cardData={cardData[102]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI07 | (6 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>

              (EF03CI07) Identificar características da Terra (como seu formato esférico, a presença de água, solo etc.), com base na observação, manipulação e comparação de diferentes formas de representação do planeta (mapas, globos, fotografias etc.).

            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[103]} />
              <MediaCard cardData={cardData[104]} />
              <MediaCard cardData={cardData[105]} />
              <MediaCard cardData={cardData[106]} />
              <MediaCard cardData={cardData[107]} />
              <MediaCard cardData={cardData[108]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI08 | (3 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI08) Observar, identificar e registrar os períodos diários (dia e/ou noite) em que o Sol, demais estrelas, Lua e planetas estão visíveis no céu.


            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[109]} />
              <MediaCard cardData={cardData[110]} />
              <MediaCard cardData={cardData[111]} />

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI09 | (2 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>

              (EF03CI09) Comparar diferentes amostras de solo do entorno da escola com base em características como cor, textura, cheiro, tamanho das partículas, permeabilidade etc.

            </Typography>
            <div className='cardsCont'>

              <MediaCard cardData={cardData[112]} />
              <MediaCard cardData={cardData[113]} />

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF03CI10| (2 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF03CI10) Identificar os diferentes usos do solo (plantação e extração de materiais, dentre outras possibilidades), reconhecendo a importância do solo para a agricultura e para a vida.

            </Typography>
            <div className='cardsCont'>

              <MediaCard cardData={cardData[114]} />
              <MediaCard cardData={cardData[115]} />

            </div>
          </AccordionDetails>
        </Accordion>

      </div>
      <div className='div4'>

        <div className='cor-heard'>CIÊNCIAS – 4º ANO</div>
        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF04CI01| (2 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF04CI01) - Identificar misturas na vida diária, com base em suas propriedades físicas observáveis, reconhecendo sua composição.

            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[116]} />
              <MediaCard cardData={cardData[117]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF04CI02| (4 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF04CI02) Testar e relatar transformações nos materiais do dia a dia quando expostos a diferentes condições (aquecimento, resfriamento, luz e umidade).

            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[118]} />
              <MediaCard cardData={cardData[119]} />
              <MediaCard cardData={cardData[120]} />
              <MediaCard cardData={cardData[121]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF04CI03| (3 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF04CI03) - Concluir que algumas mudanças causadas por aquecimento ou resfriamento são reversíveis (como as mudanças de estado físico da água) e outras não (como o cozimento do ovo, a queima do papel etc.).
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[122]} />
              <MediaCard cardData={cardData[123]} />
              <MediaCard cardData={cardData[124]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">

            <div className='container' Typography>Ciências - EF04CI04 e EF04CI05 | ( 9 - Assets)</div>

          </AccordionSummary>
          <AccordionDetails>

            <div>
              <p>(EF04CI04) - Analisar e construir cadeias alimentares simples, reconhecendo a posição ocupada pelos seres vivos nessas cadeias e o papel do Sol como fonte primária de energia na produção de alimentos.</p>
              <p>(EF04CI05) - Descrever e destacar semelhanças e diferenças entre o ciclo da matéria e o fluxo de energia entre os componentes vivos e não vivos de um ecossistema.</p>
            </div>

            <div className='cardsCont'>
              <MediaCard cardData={cardData[125]} />
              <MediaCard cardData={cardData[126]} />
              <MediaCard cardData={cardData[127]} />
              <MediaCard cardData={cardData[128]} />
              <MediaCard cardData={cardData[129]} />
              <MediaCard cardData={cardData[130]} />
              <MediaCard cardData={cardData[131]} />
              <MediaCard cardData={cardData[132]} />
              <MediaCard cardData={cardData[133]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">

            <div className='container' Typography>Ciências - EF04CI06 e EF04CI07 | ( 10 - Assets)</div>

          </AccordionSummary>
          <AccordionDetails>

            <div>
              <p>(EF04CI06) - Relacionar a participação de fungos e bactérias no processo de decomposição, reconhecendo a importância ambiental desse processo.</p>
              <p>(EF04CI07) - Verificar a participação de microrganismos na produção de alimentos, combustíveis, medicamentos, entre outros.</p>
            </div>

            <div className='cardsCont'>
              <MediaCard cardData={cardData[134]} />
              <MediaCard cardData={cardData[135]} />
              <MediaCard cardData={cardData[136]} />
              <MediaCard cardData={cardData[137]} />
              <MediaCard cardData={cardData[138]} />
              <MediaCard cardData={cardData[139]} />
              <MediaCard cardData={cardData[140]} />
              <MediaCard cardData={cardData[141]} />
              <MediaCard cardData={cardData[142]} />
              <MediaCard cardData={cardData[143]} />

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">

            <div className='container' Typography> Ciências - EF04CI08 | ( 5 - Assets)</div>

          </AccordionSummary>
          <AccordionDetails>

            <div>
              (EF04CI08) - Propor, a partir do conhecimento das formas de transmissão de alguns microrganismos (vírus, bactérias e protozoários), atitudes e medidas adequadas para prevenção de doenças a eles associadas.

            </div>

            <div className='cardsCont'>
              <MediaCard cardData={cardData[144]} />
              <MediaCard cardData={cardData[145]} />
              <MediaCard cardData={cardData[146]} />
              <MediaCard cardData={cardData[147]} />
              <MediaCard cardData={cardData[148]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">

            <div className='container' Typography>Ciências - EF04CI09 e EF04CI10 | ( 3 - Assets)</div>

          </AccordionSummary>
          <AccordionDetails>

            <div>
              <p>(EF04CI09) - Identificar os pontos cardeais, com base no registro de diferentes posições relativas do Sol e da sombra de uma vara (gnômon).</p>
              <p>(EF04CI10) - Comparar as indicações dos pontos cardeais resultantes da observação das sombras de uma vara (gnômon) com aquelas obtidas por meio de uma bússola.</p>
            </div>

            <div className='cardsCont'>
              <MediaCard cardData={cardData[149]} />
              <MediaCard cardData={cardData[150]} />
              <MediaCard cardData={cardData[151]} />
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">

            <div className='container' Typography>Ciências - EF04CI11 | ( 3 - Assets)</div>

          </AccordionSummary>
          <AccordionDetails>

            <div>
              (EF04CI11) - Associar os movimentos cíclicos da Lua e da Terra a períodos de tempo regulares e ao uso desse conhecimento para a construção de calendários em diferentes culturas.
            </div>

            <div className='cardsCont'>
              <MediaCard cardData={cardData[152]} />
              <MediaCard cardData={cardData[153]} />
              <MediaCard cardData={cardData[154]} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className='div5'>
        <div className='cor-heard'>CIÊNCIAS – 5º ANO</div>
        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF05CI01 | ( 3 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              (EF05CI01) - Explorar fenômenos da vida cotidiana que evidenciem propriedades físicas dos materiais – como densidade, condutibilidade térmica e elétrica, respostas a forças magnéticas, solubilidade, respostas a forças mecânicas (dureza, elasticidade etc.), entre outras.
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[155]} />
              <MediaCard cardData={cardData[156]} />
              <MediaCard cardData={cardData[157]} />
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header">
            <div className='container' Typography>Ciências - EF05CI02 e EF05CI04 | ( 7 - Assets)</div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            </Typography>
            <div className='cardsCont'>
              <MediaCard cardData={cardData[158]} />
              <MediaCard cardData={cardData[159]} />
              <MediaCard cardData={cardData[160]} />
              <MediaCard cardData={cardData[161]} />
              <MediaCard cardData={cardData[162]} />
              <MediaCard cardData={cardData[163]} />
              <MediaCard cardData={cardData[164]} />
            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div>
  );
}