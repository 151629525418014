// Componente React MediaCard
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import './MediaCard.css';

export default function MediaCard({ cardData }) {
  const [open, setOpen] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState('');

  const handleOpen = (AssetsLink) => {
    setOpen(true);
    setSelectedAssets(AssetsLink);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAssets('');
  };

  return (
    <div className="cards-container">
      <Card key={cardData.id} sx={{ maxWidth: 300, flex: 1 }}>
        <CardMedia component="img" alt="assets" height="150" image={cardData.image} />

        <CardContent>
          <Typography gutterBottom variant="h7" component="div">
            {cardData.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cardData.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => handleOpen(cardData.AssetsLink)}>
            <center>Clique aqui e experimente</center>
          </Button>
        </CardActions>
      </Card>
      <Modal open={open} onClose={handleClose} className="modal">
        <div className="modal-content">
          <CloseIcon className="close-icon" onClick={handleClose} />
          <iframe title="Assets Modal" width="" height="" src={selectedAssets} allowFullScreen></iframe>
        </div>
      </Modal>
    </div>
  );
}
