
import Routes from './components/routes/routes';

function App() {
  return (
    <div >
      <Routes />
    </div>
  );
}
export default App;
