
const cardData = [
  //  ------------------------------------------------------------ Ciências - EF01CI01  -------------------------------------------------------------------------------------------//
  {
    id: 0,
    title: 'Vídeo - Consumo responsável',
    description: 'Vídeo educativo para crianças com o qual vão aprender o que é o consumo responsável através dos três R s: reduzir, reutilizar e reciclar. O consumo responsável é um conceito que fala sobre a importância de consumir produtos de acordo com as nossas necessidades reais, escolhendo opções que favoreçam o cuidado do meio ambiente e o equilíbrio social. Às vezes as pessoas compram e consomem muitos produtos, mais do que precisam: roupas demais, brinquedos demais, aparelhos eletrônicos demais. Você acha que realmente precisamos de todas essas coisas? Poderíamos tentar aproveitar mais o que temos sem comprar coisas novas? Ótimo material para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/tqr9ww9TTY8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDMR0Mdx_KDv0dvdeH-rHaV7rBvQw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Consumo_Responsable_PT.mp4'
  },
  {
    id: 1,
    title: 'Quiz - Consumo responsável ',
    description: 'O quiz de consumo responsável é um questionário interativo para reforçar a aprendizagem teórica da coleção de aplicativos e vídeos sobre as energias e seus usos.',
    image: 'https://i.ytimg.com/vi/tqr9ww9TTY8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDMR0Mdx_KDv0dvdeH-rHaV7rBvQw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/RESPONSIBLECONSUMPTION_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },

  {
    id: 2,
    title: 'Game - Reciclagem ',
    description: 'Reciclagem é um jogo interativo relacionado à inteligência naturalista, que serve para aprender a separar os resíduos em diferentes grupos. Presenta-se um guia padrão sobre como reciclar e separar os resíduos nas diferentes latas de lixo, além de alguns conselhos sobre como cuidar do meio ambiente e curiosidades sobre reciclagem e o seu efeito sobre a Terra. Tem dois níveis para praticar a separar os resíduos de forma prática.',
    image: 'https://i.ytimg.com/vi/Ekbd_hSQOhc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDhydYV4u2A4p2j4AI_eBXH8AEx0w',
    AssetsLink: 'https://iptv.smileandlearn.com/game/RECYCLING/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 3,
    title: 'Vídeo  - Características dos materiais',
    description: 'Vídeo educativo para crianças apresentando diferentes características dos objetos do cotidiano. Existem objetos antigos que ainda estão presentes no nosso dia a dia e que apenas passaram por transformações no aspecto físico e na mudança no tipo de material usado em sua fabricação. Muitos dos materiais fabricados são de origem mineral, como o ferro, o alumínio e o petróleo, ou de origem vegetal, como a madeira, sementes e as fibras vegetais, como bambu, tábua e cipó. Dessa forma, as crianças vão compreender as características dos materiais na produção de objetos.',
    image: 'https://school-static.ip.tv/permanent/64c11227f7d0d5664df7aba2/full.png',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF01CI01/caracteristicasdosmateriais.mp4'
  },
  {
    id: 4,
    title: 'Quiz - Características dos materiais',
    description: 'O quiz sobre características de diferentes materiais é um questionário interativo para reforçar a aprendizagem teórica sobre os materiais utilizados na produção de objetos.',
    image: 'https://school-static.ip.tv/permanent/64c11227f7d0d5664df7aba2/full.png',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF01CI01'
  },



  
  //  ------------------------------------------------------------ Ciências - EF01CI02  -------------------------------------------------------------------------------------------//



  {
    id: 5,
    title: 'Vídeo - Os sentidos ',
    description: 'Vídeo educativo para crianças com o qual vão aprender o que são e como funcionam os sentidos: o paladar, o tato, a visão, a audição e o olfato. O paladar nos permite identificar e conhecer os sabores dos alimentos. O tato nos permite detectar, identificar e diferenciar as qualidades dos objetos. Graças à visão, podemos ver os objetos e o espaço ao nosso redor. A audição nos permite escutar os sons e o olfato a detectar os cheiros. Vários órgãos interferem nos sentidos e cada sentido nos ajuda a entender o mundo e a desfrutar das coisas que gostamos. Também nos ajudam a identificar perigos.',
    image: 'https://i.ytimg.com/vi/NdCZ5WNE5v8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDB1kULVa6S5TB5thnr53Uyd_ONUw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Sentidos_PT.mp4'
  },
  {
    id: 6,
    title: 'Quiz - Os Sentidos ',
    description: 'O quiz de sentidos é um questionário interativo para reforçar a aprendizagem teórica da coleção de aplicativos e vídeos sobre os sentidos.',
    image: 'https://i.ytimg.com/vi/NdCZ5WNE5v8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDB1kULVa6S5TB5thnr53Uyd_ONUw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/SENSES_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },

  {
    id: 7,
    title: ' Vídeo - O Corpo  ',
    description: 'Neste vídeo educativo, as crianças podem aprender mais sobre as partes do corpo. Com a nossa recopilação de vídeos, vão conhecer o próprio corpo e o nome de cada uma de suas partes como, por exemplo, a cabeça, o tronco e as extremidades. Este vídeo faz parte de uma coleção de vocabulário, para as crianças aprenderem novas palavras sobre temas variados. Sempre da maneira mais simples e divertida possível, com exemplos claros, para que aprendam mais rápido e reforcem os conhecimentos adquiridos.',
    image: 'https://i.ytimg.com/vi/-3adho_PG3I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCH4E-lH5R-jY5svfiLmtflpRdKqw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Cuerpo_Vocabulario_PT.mp4'
  },
  {
    id: 8,
    title: ' Game - Corpo humano - Vocabulário',
    description: 'Jogo de vocabulário em vários idiomas, conhecendo o corpo humano, família de palavras, língua, léxico.',
    image: 'https://i.ytimg.com/vi/qelEiERtEUY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB-K6B29RAxuibhPAksCxvEHCDd1A',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VOCABULARYHUMANBODY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 9,
    title: 'Game - As partes do seu corpo  ',
    description: 'As partes do seu corpo é um jogo interativo para aprender a identificar as partes do corpo humano e os seus nomes. Tem um único nível para a aprendizagem dos mais jovens, mediante quebra-cabeças e associações. Além disso, tem um quiz para praticar o conteúdo aprendido.',
    image: 'https://i.ytimg.com/vi/m7DOqbg5B0I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCuPqsETgVgpLvyK6VOriV4WLZuYg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HUMANBODY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 10,
    title: 'Game - Desenhar retratos',
    description: 'Desenhar retratos» é um jogo para aprender a desenhar diferentes rostos e os seus elementos. Siga todos os passos e torne-se um grande artista.',
    image: 'https://i.ytimg.com/vi/jShosUshmuE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCGvYX3TVDJ33PbMXmpIaIbNlB9aA',
    AssetsLink: 'https://iptv.smileandlearn.com/game/DRAWFACESEASY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 11,
    title: ' Vídeo - O corpo humano',
    description: 'O vídeo apresenta de forma lúdica e divertida as diferentes partes do corpo humano, assim como suas funções. O corpo humano é dividido em cabeça, tronco e membros superiores e inferiores. Através desse vídeo, as crianças serão capazes de compreender de forma interativa as partes e funções do corpo humano.',
    image: 'https://i.ytimg.com/vi/oVyrvilUJlg/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAWhefUUTtJ5uq-ymiVhEUkZWXiIw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF01CI02/ocorpohumano.mp4'
  },
  {
    id: 12,
    title: 'Quiz - O corpo humano',
    description: 'O quiz sobre o corpo humano é um questionário interativo para reforçar a aprendizagem teórica sobre as partes e funções do corpo.',
    image: 'https://i.ytimg.com/vi/oVyrvilUJlg/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAWhefUUTtJ5uq-ymiVhEUkZWXiIw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF01CI02'
  },

  //  ------------------------------------------------------------ Ciências - EF01CI03  -------------------------------------------------------------------------------------------//
  {
    id: 13,
    title: 'Vídeo - Lavar as mãos',
    description: 'Vídeo educativo para que as crianças aprendam vários hábitos de higiene corporal. Além de lavar as mãos, alguns hábitos de higiene corporal são fundamentais para cuidar do nosso corpo. Um dos mais importantes é tomar banho pelo menos uma vez por dia, principalmente depois de fazer esporte. Durante o banho, ensaboamos o corpo, a cabeça e por último enxaguamos tudo. Quando saímos do banho, é importante se secar com uma toalha limpa. Além disso, temos que pentear o cabelo, usar roupa limpa e ir cortando as unhas conforme forem crescendo. Outro hábito de higiene diário super importante é escovar os dentes depois de cada refeição e lavar o rosto antes de dormir e quando acordamos. Lembre-se que a higiene é importante para nos sentirmos mais limpos e saudáveis.',
    image: 'https://i.ytimg.com/vi/V1cYKFhJNjA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCl5dAf6TUQpc3G75atgYYQY7A4uw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Lavado_Manos_PT.mp4'
  },
  {
    id: 14,
    title: ' Game - Lavar as mãos - atividade',
    description: 'Lavar as mãos» é um jogo interativo para praticar o hábito de higiene de lavar as mãos, cuidando do seu avatar.',
    image: 'https://i.ytimg.com/vi/V1cYKFhJNjA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCl5dAf6TUQpc3G75atgYYQY7A4uw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HYGIENEHANDS_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 15,
    title: 'Vídeo - Higiene corporal',
    description: 'Vídeo educativo para crianças com o qual elas vão aprender todos os passos para lavar as mãos. Lavar as mãos frequentemente é muito importante para evitar contagiar os outros e nós mesmos com microorganismos como os vírus ou bactérias. Existem 10 passos para lavar as mãos: primeiro molhar e colocar sabão. Depois esfregar bem as palmas. O terceiro passo é esfregar o dorso das mãos com as palmas. Depois, entrelaçar os dedos e continuar esfregando. Fechar os dedos e esfregar o dorso com a palma da mão contrária. No sexto passo, esfregar bem os polegares. Depois, a ponta dos dedos para tirar a sujeira das unhas. Depois os pulsos. O nono passo é enxaguar as mãos com água e tirar o sabão e por último secar com uma toalha limpa e seca. Este vídeo faz parte de uma coleção sobre saúde e hábitos de higiene.',
    image: 'https://i.ytimg.com/vi/GFCDo9QtQ8E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCQ7UFGkIrRUv_fnlhqN7mjhydWEQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Habitos_Higiene_PT.mp4'
  },
  {
    id: 16,
    title: 'Game - Atividade Higiene Corporal',
    description: '“Hábitos de higiene” é um jogo interativo para praticar os hábitos de limpeza corporal diários cuidando do seu avatar.',
    image: 'https://i.ytimg.com/vi/GFCDo9QtQ8E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCQ7UFGkIrRUv_fnlhqN7mjhydWEQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HYGIENEAVATAR_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 17,
    title: 'Quiz - Hábitos de higiene ',
    description: 'O quiz de hábitos de higiene é um questionário interativo para reforçar a aprendizagem teórica da coleção de aplicativos e vídeos sobre os hábitos de higiene.',
    image: 'https://i.ytimg.com/vi/SPRKM-jX_W4/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBK_d0BLKgtn0BLauPHguzlL3rtUQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HYGIENEHABITS_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 19,
    title: 'Vídeo - Escovar os dentes',
    description: 'Vídeo educativo para crianças com o qual vão aprender os passos para escovar bem os dentes. O primeiro passo é pôr a pasta de dentes na escova. Logo, começamos a limpar o fundo da boca, de cima para baixo na parte exterior. O terceiro passo é limpar a parte interior dos dentes. Depois limpamos o resto da boca: a parte interior das bochechas e a língua. Por último, cuspimos a espuma e enxaguamos bem a boca. Lembre-se que você pode usar o fio dental para eliminar os restos da comida e bochechar com flúor para uma limpeza completa. Uma boa alimentação e não comer muitos doces são dois conselhos fundamentais para ter dentes bonitos. Este vídeo faz parte de uma coleção sobre a saúde e hábitos de higiene.',
    image: 'https://i.ytimg.com/vi/qHI-CybmJOM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDNGICb1PJr2gdq2Rl7T3NUcR0plA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Lavado_Dientes_PT.mp4'
  },
  {
    id: 19,
    title: 'Game - Escovar os dentes - atividade',
    description: '«Escovar os dentes» é um jogo interativo para praticar o hábito de higiene de escovar os dentes, cuidando do seu avatar.',
    image: 'https://i.ytimg.com/vi/qHI-CybmJOM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDNGICb1PJr2gdq2Rl7T3NUcR0plA',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HYGIENETEETH_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 20,
    title: 'Vídeo - Hábitos de higiene',
    description: 'Nesse vídeo educativo, as crianças vão compreender a importância da higiene para o corpo. Atitudes como escovar os dentes, cortar as unhas e lavar as mãos devem se tornar hábitos frequentes. Além disso, tomar banho, pentear os cabelos e utilizar calçados adequados são cuidados essenciais para a saúde do corpo. Os hábitos de higiene são medidas, ações e práticas que adotamos para prevenir doenças e preservar a nossa saúde.',
    image: 'https://i.ytimg.com/vi/rZr8geozI_s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBgKBsNn7IdY2lGpQhAbjQxMiqxhQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF01CI03/habitosdehigiene.mp4'
  },
  {
    id: 21,
    title: 'Quiz - Hábitos de higiene',
    description: 'O quiz sobre hábitos de higiene é um questionário interativo que tem como objetivo reforçar a aprendizagem teórica dos hábitos de higiene essenciais para a manutenção da nossa saúde.',
    image: 'https://i.ytimg.com/vi/SPRKM-jX_W4/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBK_d0BLKgtn0BLauPHguzlL3rtUQ',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF01CI03'
  },

  //  ------------------------------------------------------------ Ciências - EF01CI04  -------------------------------------------------------------------------------------------//

  {
    id: 22,
    title: 'Quiz - Características Físicas ',
    description: 'O quiz sobre o respeito à diversidade fortalece a compreensão teórica acerca da importância de respeitar a forma única como cada indivíduo brinca e age.',
    image: 'https://school-static.ip.tv/permanent/64c11227f7d0d5664df7aba2/full.png',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF01CI04'
  },
  //  ------------------------------------------------------------ Ciências - EF01CI05  -------------------------------------------------------------------------------------------//
  {
    id: 23,
    title: 'Vídeo - Escalas de Tempo',
    description: 'Neste vídeo, as crianças vão reconhecer as mudanças que ocorrem no céu à medida que o tempo passa e as atividades que são realizadas durante o dia ajudam a perceber essa passagem  do tempo. Ao olharmos para o céu durante o dia vemos o  Sol  e durante a noite a Lua, que também é conhecida como satélite natural da Terra. Tudo isso só é possível devido ao movimento que a Terra faz ao girar em torno de si mesma.',
    image: 'https://i.ytimg.com/vi/Rq0Tvsmd-6I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBPFB3po8ksWjKxKddbGyjpoEZPRQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF01CI05/escalasdetempo.mp4'
  },
  {
    id: 24,
    title: 'Quiz - Escalas de Tempo ',
    description: 'O quiz sobre a escala de tempo reforça a aprendizagem teórica sobre as mudanças que ocorrem no céu à medida que o tempo passa.',
    image: 'https://i.ytimg.com/vi/Rq0Tvsmd-6I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBPFB3po8ksWjKxKddbGyjpoEZPRQ',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF01CI0506'
  },
  //  ------------------------------------------------------------ Ciências - EF01CI06  -------------------------------------------------------------------------------------------//

  {
    id: 25,
    title: 'Vídeo - Contando o tempo',
    description: 'O vídeo educativo apresenta de forma cativante a importância da medida de tempo, com o objetivo de auxiliar na organização temporal através das semanas, meses e anos. Ao longo da história da humanidade, temos buscado diferentes formas de marcar a passagem do tempo. Assim, as crianças terão uma experiência divertida ao aprender sobre os instrumentos utilizados para medir o tempo: o calendário e o relógio.',
    image: 'https://i.ytimg.com/vi/RQhHBUfOwpw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB2j_0xI8NRJw--GKVvng1l7TOFrA',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF01CI06/medidasdetempo.mp4'
  },
  {
    id: 26,
    title: 'Quiz - Contando o tempo ',
    description: 'O quiz sobre a medida de tempo reforça a aprendizagem teórica sobre a importância da organização temporal através das semanas, meses e anos.',
    image: 'https://i.ytimg.com/vi/RQhHBUfOwpw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB2j_0xI8NRJw--GKVvng1l7TOFrA',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF01CI0506_2'
  },


  //  ------------------------------------------------------------ Ciências - EF02CI01  -------------------------------------------------------------------------------------------//

  {
    id: 27,
    title: 'Vídeo - Propriedades e uso dos Materiais',
    description: 'Trata-se de um vídeo educativo que apresenta os materiais (metais, madeira, vidro, etc.) utilizados na criação de objetos que fazem parte do nosso cotidiano, mostrando como esses objetos são utilizados e com quais materiais eram produzidos no passado. No passado, a maioria dos brinquedos eram fabricados de forma artesanal. Atualmente, ainda podemos encontrar brinquedos de madeira, bonecas de pano ou porcelana. Entretanto, a maioria dos brinquedos disponíveis atualmente, ou seja, aqueles fabricados pela indústria, são feitos predominantemente de plástico, um material desenvolvido a partir do petróleo.',
    image: 'https://i.ytimg.com/vi/3NQW4hj2gTk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCa3SfsacghLm40LWdZhLfK4DqHFw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI01/propriedadeseusosdosmateriais.mp4'
  },
  {
    id: 28,
    title: 'Quiz - Propriedades e uso dos Materiais ',
    description: 'O quiz sobre Propriedades e uso dos Materiais reforça a aprendizagem teórica sobre a importância de reconhecer os materiais utilizados na fabricação dos objetos do cotidiano.',
    image: 'https://i.ytimg.com/vi/3NQW4hj2gTk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCa3SfsacghLm40LWdZhLfK4DqHFw',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI01'
  },
  //  ------------------------------------------------------------ Ciências - EF02CI02  -------------------------------------------------------------------------------------------//


  {
    id: 29,
    title: 'Vídeo - Propriedade e uso dos materiais',
    description: 'Nesse vídeo, são apresentados os diferentes materiais utilizados na construção de objetos de uso cotidiano, levando em consideração suas propriedades específicas, como flexibilidade, dureza, transparência, entre outras. Alguns materiais, quando em contato com a água, apresentam diferentes comportamentos: podem absorvê-la ou ser impermeáveis, ou seja, impedir a passagem de líquidos, principalmente água. Por exemplo, a sombrinha e a capa de chuva são materiais impermeáveis, enquanto o jornal e o tecido são materiais permeáveis. Dessa forma, as crianças serão capazes de compreender as propriedades e o uso dos materiais de forma mais completa.',
    image: 'https://i.ytimg.com/vi/YXKLna8zboY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCp49kkpF0RSO_me4LvQQFkGJjsxw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI02/propriedadeseusosdosmateriais.mp4'
  },
  {
    id: 30,
    title: 'Quiz - Propriedade e uso dos materiais',
    description: 'O quiz sobre Propriedades e uso dos Materiais reforça a aprendizagem teórica sobre a importância de reconhecer diferentes materiais utilizados na construção de objetos de uso cotidiano',
    image: 'https://i.ytimg.com/vi/YXKLna8zboY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCp49kkpF0RSO_me4LvQQFkGJjsxw',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI02'
  },
  //  ------------------------------------------------------------ Ciências - EF02CI03  -------------------------------------------------------------------------------------------//
  {
    id: 31,
    title: ' Vídeo -  Prevenção de Acidentes Domésticos',
    description: 'Neste vídeo, são apresentados os cuidados necessários para a prevenção de acidentes domésticos, destacando a importância da atenção que devemos ter em relação aos objetos cortantes e inflamáveis, eletricidade, produtos de limpeza, medicamentos, entre outros. A eletricidade pode representar um risco significativo para nossa vida de diversas maneiras. Ao observar o ambiente ao nosso redor, é possível identificar situações que podem apresentar perigo ou segurança. Ligar fios na tomada com o chão molhado, esquecer o ferro ligado e até mesmo manipular fios descascados são exemplos de situações comuns em nosso cotidiano que podem oferecer riscos à nossa segurança. Em trabalhos realizados em alturas, por exemplo, um choque elétrico, mesmo de baixa intensidade, pode resultar em quedas fatais. A melhor maneira de evitar esses tipos de acidentes é brincar apenas com nossos brinquedos, pois os objetos presentes no banheiro, cozinha ou qualquer outro ambiente não foram feitos para brincadeiras.',
    image: 'https://i.ytimg.com/vi/yhvHsg8Epso/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBaZfoazb6rJvrW0DiE0nzMHKOTpQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI03/prevencaodeacidentesdomesticos.mp4'
  },
  {
    id: 32,
    title: 'Quiz - Prevenção de Acidentes ',
    description: 'O quiz sobre os cuidados necessários para a prevenção de acidentes domésticos reforça a aprendizagem teórica sobre a importância de ter atenção com objetos cortantes e inflamáveis, eletricidade, produtos de limpeza, medicamentos, entre outros. O quiz proporciona uma oportunidade de testar e consolidar os conhecimentos adquiridos, incentivando a prática dos cuidados preventivos no dia a dia. Com perguntas relacionadas a cada área de risco, o quiz ajuda a reforçar a conscientização sobre a segurança em casa e a importância de adotar medidas preventivas para evitar acidentes.',
    image: 'https://i.ytimg.com/vi/yhvHsg8Epso/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBaZfoazb6rJvrW0DiE0nzMHKOTpQ',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI03'
  },
  //  ------------------------------------------------------------ Ciências - EF02CI04, EF02CI05 e EF02CI06 -------------------------------------------------------------------------------------------//
  {
    id: 33,
    title: 'Vídeo - Animais Domésticos',
    description: 'Vídeo educativo para crianças onde podem aprender vocabulário e descobrir os animais da fazenda, como a vaca, o cavalo, a ovelha, a galinha, o coelho, o burro, a cabra, o cachorro, o gato, o pato e muito mais. Você sabe como é o som do burro? E o da ovelha? Descubra isto e muito mais neste vídeo, além de outras curiosidades.Este vídeo faz parte de uma coleção de vocabulário para crianças, na qual aprendem os animais e os sons que eles fazem, de uma maneira divertida.',
    image: 'https://i.ytimg.com/vi/6l9Y3mbds4E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBGyD9ZuDvmLvIPZGTBRN1Z3Lgvvw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Animales_Granja_Vocabulario_PT.mp4'
  },
  {
    id: 34,
    title: ' Vídeo - Animais Selvagens',
    description: 'Vídeo educativo para crianças onde podem aprender vocabulário e descobrir os animais selvagens, como o leão, o elefante, a girafa, o macaco, o crocodilo, o gorila, a zebra, o tigre, o rinoceronte, o urso, o lobo e muito mais.',
    image: 'https://i.ytimg.com/vi/BLYr11fcC58/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDWrOHCAo_xDE5YM9RUK8mp3cYoFA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Animales_Salvajes_Vocabulario_PT.mp4'
  },
  {
    id: 35,
    title: 'Vídeo - Animais Aquáticos',
    description: 'Vídeo educativo para crianças onde podem aprender vocabulário e descobrir os animais aquáticos, como a foca, o golfinho, o tubarão, a tartaruga, a baleia, o peixe palhaço, o polvo, o caranguejo, o cavalo-marinho, a medusa e muito mais. Você sabe quantas patas o polvo tem? Descubra isto e muito mais neste vídeo, onde você vai aprender alguns dos animais aquáticos, além de outras curiosidades. Este vídeo faz parte de uma coleção de vocabulário para crianças, na qual aprendem os animais e os seus sons de uma maneira divertida.',
    image: 'https://i.ytimg.com/vi/cxmC8Mn8iE4/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA99l0khYcL7aVF4NWXOEjI6x69XA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Animales_Agua_Vocabulario_PT.mp4'
  },
  {
    id: 36,
    title: 'Vídeo - Animais voadores - Aves',
    description: 'Vídeo educativo para crianças, onde vão descobrir algumas curiosidades sobre os animais ovíparos. Estes vertebrados nascem dos ovos, caminham sobre duas patas e têm asas que lhes permitem voar. Alimentam-se através do bico e têm o corpo coberto de penas. Entre as aves está a águia, o pinguim e o avestruz. Este vídeo faz parte de uma coleção sobre os animais. Recomendado para crianças do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/jvw1pS5DgKY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAG8HYEH0A_3fNx1TpVJJ58Nrqp7w',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Aves_PT.mp4'
  },
  {
    id: 37,
    title: 'Game Aves',
    description: '“Aves” é um jogo interativo para diferenciar as aves, pelas características delas, dos outros animais vertebrados. Inclui dois jogos de identificação das aves, de acordo com as características e sons. Além de um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/jvw1pS5DgKY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAG8HYEH0A_3fNx1TpVJJ58Nrqp7w',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEBIRDS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 38,
    title: 'Vídeo - Animais voadores - Borboletas',
    description: 'Vídeo educativo para crianças com o qual vão aprender as características e curiosidades sobre as borboletas. As borboletas são animais invertebrados e pertencem ao grupo dos insetos. 99% das espécies de borboletas são herbívoras e se alimentam de folhas e néctar. Somente 1% das espécies são carnívoras e se alimentam de pequenos insetos. O seu peso pode variar de 5 miligramas a 12 gramas e o seu comprimento pode variar de 3 milímetros a 30 centímetros.',
    image: 'https://i.ytimg.com/vi/ptPutaKYbj0/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA5BDt0akOWSd1UCZ4QN5KcY9wimQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/ANIMALSBUTTERFLY_PT.mp4'
  },
  {
    id: 39,
    title: ' Vídeo - Animais Anfíbios',
    description: 'Vídeo educativo para crianças, com o qual vão descobrir outro grupo de animais vertebrados: os anfíbios. Estes vertebrados são ovíparos e podem viver tanto na água, quanto na terra. Também vão aprender como eles respiram, onde nascem e o que é a metamorfose, além de outras curiosidades.',
    image: 'https://i.ytimg.com/vi/a5YCne38E7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA1XuQZYIhHAF3IqlgZcALlZG4PdQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Anfibios_PT.mp4'
  },
  {
    id: 40,
    title: 'Game - Anfíbios ',
    description: '“Anfíbios” é um jogo interativo para diferenciar os anfíbios, pelas características deles, dos outros animais vertebrados. Inclui um jogo e um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/a5YCne38E7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA1XuQZYIhHAF3IqlgZcALlZG4PdQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEAMPHIBIANS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 41,
    title: 'Vídeo - Mamíferos',
    description: 'Vídeo educativo para crianças onde vão descobrir os animais mamíferos. Estes vertebrados ovíparos respiram através dos pulmões e são de sangue quente. A maioria deles têm o corpo coberto de pelo e vivem na terra e na água. Às vezes podem até voar. Entre os mamíferos estão os cachorros, os cavalos, as vacas e os humanos. Este vídeo faz parte de uma coleção sobre animais. Perfeito para crianças do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/4ojrOwCPkHQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCOCKO2d7DdIWEpuAY-dHsEpUgRDA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Mamiferos_PT.mp4'
  },
  {
    id: 42,
    title: 'Game - Mamíferos ',
    description: '“Mamíferos” é um jogo interativo para diferenciar os mamíferos, pelas características deles, dos outros animais vertebrados. Inclui dois jogos de identificação dos animais mamíferos, de acordo com as características e sons. Além de um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/4ojrOwCPkHQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCOCKO2d7DdIWEpuAY-dHsEpUgRDA',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATESMAMMALS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 43,
    title: 'Vídeo - As partes das plantas',
    description: 'Vídeo educativo para crianças sobre as partes e as características das plantas. Descubra o que é a raiz, o caule e as folhas e qual é a diferença entre as plantas e as árvores. Aprenda como respiram e conseguem o alimento através da fotossíntese.',
    image: 'https://i.ytimg.com/vi/2nkMyaU9k7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3HPDOMu12t4B9Qpc_yjDRgcCqpw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Partes_Planta_PT.mp4'
  },
  {
    id: 44,
    title: 'Game - As partes das plantas ',
    description: 'A planta é um jogo interativo para identificar e conhecer as partes de uma planta. O jogo inclui quebra-cabeças, um caderno de pesquisa, e um quiz para praticar o aprendizado.',
    image: 'https://i.ytimg.com/vi/2nkMyaU9k7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3HPDOMu12t4B9Qpc_yjDRgcCqpw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/PLANTPARTS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 45,
    title: 'Game - Interação das plantas com a luz solar',
    description: 'Nutrição da Planta é um jogo interativo para identificar e conhecer como se alimentam as plantas pela fotossíntese e o processo de respiração. O jogo inclui um quebra-cabeça de sequências, para organizar as fases dos processos implicados na nutrição. Além disso, podemos praticar o que aprendemos com um quiz.',
    image: 'https://i.ytimg.com/vi/oeJt_oxno54/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAxhoZPwEZ1reSOtPq_b58R-7xHgw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/CLASSIFYANIMALS_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 46,
    title: 'Vídeo - Classificação dos Animais ',
    description: 'Animais vertebrados e invertebrados é um jogo interativo para identificar e distinguir entre animais vertebrados e invertebrados por suas características. Inclui um jogo para classificar os animais de acordo com suas características e praticar o que aprenderam.',
    image: 'https://i.ytimg.com/vi/yFkpx-ICTPw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8S49Mlw9_SENnRCDtC25xi2MKeg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/CLASSIFYANIMALS_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },


  {
    id: 47,
    title: 'Vídeo  - seres vivos no ambiente',
    description: 'Nesse vídeo, são apresentadas as características de plantas e animais, como tamanho, forma, cor, fase da vida e local onde se desenvolvem, que fazem parte do nosso cotidiano, relacionando-as ao ambiente em que vivem. Mas afinal, o que diferencia um ser vivo? Primeiramente, é importante compreender que os seres vivos são organismos que possuem características indispensáveis que os distinguem dos seres não vivos. Por exemplo, uma planta é considerada um ser vivo devido a propriedades como crescimento, reprodução e organização celular. Essas características essenciais distinguem os seres vivos das demais formas de existência presentes no ambiente. Enquanto os seres vivos interagem ativamente com seu ambiente, adaptando-se e respondendo a estímulos, os elementos não vivos do ambiente não possuem essas capacidades. Portanto, entender as diferenças entre seres vivos e elementos não vivos é fundamental para compreendermos a complexidade e a diversidade da vida em nosso mundo.',
    image: 'https://i.ytimg.com/vi/HR0fRyYjF9A/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCFNfZaG6JLj1TeNOxF6B60YqGB8g',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI04/seresvivosnoambiente.mp4'
  },
  {
    id: 48,
    title: 'Vídeo - importância da água e da luz para as plantas',
    description: 'Nesse vídeo, é destacada a importância da água e da luz para a manutenção da vida das plantas de forma geral. Você já parou para pensar em como as plantas conseguem obter água? Para a maioria das plantas terrestres, a principal fonte de água é o solo, e elas conseguem absorvê-la por meio das raízes. Além de desempenharem essa função de absorção, as raízes também têm outras importantes funções para as plantas, como fixá-las no solo e absorver nutrientes necessários para o seu desenvolvimento.',
    image: 'https://i.ytimg.com/vi/BXJk6ngNnVw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBLz5sgFxS2YUrGvczMVExV8qN78Q',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI05/importanciadaaguaedaluzparaasplantas.mp4'
  },
  {
    id: 49,
    title: 'Vídeo - partes das plantas e suas funções',
    description: 'Nesse vídeo, são apresentadas as principais partes de uma planta, como raiz, caule, folhas, flores e frutos, destacando a função desempenhada por cada uma delas. Além disso, são analisadas as relações existentes entre as plantas, o ambiente e os demais seres vivos. Assim como os órgãos do corpo humano desempenham funções vitais, cada parte da planta possui um papel importante para o vegetal. Explorando essas características, o vídeo busca proporcionar uma compreensão mais abrangente sobre as plantas e sua interação com o ambiente e outros seres vivos.',
    image: 'https://i.ytimg.com/vi/2nkMyaU9k7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3HPDOMu12t4B9Qpc_yjDRgcCqpw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI06/partesdasplantasesuasfuncoes.mp4'
  },
  {
    id: 50,
    title: 'Quiz - Seres vivos no ambiente',
    description: 'O quiz sobre as características de plantas e animais tem como objetivo reforçar a aprendizagem teórica sobre a importância de reconhecer as características dos seres vivos, incluindo seu ciclo de vida, bem como suas necessidades de respiração, água e alimento para sobreviver. O quiz busca fortalecer o conhecimento sobre esses aspectos essenciais, incentivando uma compreensão mais profunda e precisa das características dos seres vivos e sua relação com o ambiente.',
    image: 'https://i.ytimg.com/vi/HR0fRyYjF9A/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCFNfZaG6JLj1TeNOxF6B60YqGB8g',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI04'
  },
  {
    id: 51,
    title: 'Quiz -  Importância da água e da luz para as plantas',
    description: 'O quiz sobre a importância da água e da luz para a manutenção da vida das plantas tem como objetivo reforçar a aprendizagem teórica sobre a importância de reconhecer o papel essencial desses elementos na sobrevivência das plantas. O quiz busca fortalecer o conhecimento sobre a relevância da água e da luz para o desenvolvimento saudável das plantas, incentivando uma compreensão mais aprofundada das interações entre as plantas e seu ambiente.',
    image: 'https://i.ytimg.com/vi/BXJk6ngNnVw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBLz5sgFxS2YUrGvczMVExV8qN78Q',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI06'
  },
  {
    id: 52,
    title: ' Quiz - Partes das plantas e suas funções',
    description: 'O quiz sobre as principais partes de uma planta reforça a aprendizagem teórica sobre a importância de reconhecer as diferentes partes de uma planta e compreender suas funções específicas. Esse quiz busca fortalecer o conhecimento acerca das partes fundamentais de uma planta, incentivando uma compreensão mais aprofundada da estrutura e do funcionamento das plantas.',
    image: 'https://i.ytimg.com/vi/2nkMyaU9k7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB3HPDOMu12t4B9Qpc_yjDRgcCqpw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI05'
  },
  //  ------------------------------------------------------------ Ciências - EF02CI07 -------------------------------------------------------------------------------------------//


  {
    id: 53,
    title: 'Vídeo  - o Sol ao Longo do Dia',
    description: 'Nesse vídeo, são apresentadas as diferentes posições do Sol ao longo do dia, associando-as ao tamanho da sombra projetada. Além disso, destaca-se a importância de observar, identificar e registrar os períodos diários em que o Sol, outras estrelas, a Lua e os planetas são visíveis no céu. Esse vídeo visa promover a compreensão sobre as variações da posição do Sol, bem como incentivar a observação e o registro dos fenômenos celestes, permitindo uma maior compreensão do nosso ambiente e do Sistema Solar.',
    image: 'https://i.ytimg.com/vi/gngWF34dKBk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCl4A1u40ToHkmtldBIUSiB-1X52g',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI07-EF03CI08/periodosdodia.mp4'
  },
  {
    id: 54,
    title: ' Quiz - O Sol ao Longo do Dia ',
    description: 'O quiz sobre as posições do Sol em diversos horários do dia, associando-as ao tamanho da sombra projetada, assim como a importância de observar, identificar e registrar os períodos diários em que o Sol, outras estrelas, a Lua e os planetas são visíveis no céu, reforça a aprendizagem teórica sobre a importância desses fenômenos celestes. O quiz busca fortalecer o conhecimento teórico, incentivando a compreensão da relação entre as posições do Sol, a projeção das sombras e os períodos de visibilidade dos astros no céu. Através desse aprendizado, é possível obter uma compreensão mais ampla do nosso ambiente, bem como do funcionamento do Sistema Solar.',
    image: 'https://i.ytimg.com/vi/gngWF34dKBk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCl4A1u40ToHkmtldBIUSiB-1X52g',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI0708'
  },
  //  ------------------------------------------------------------ Ciências - EF02CI08 -------------------------------------------------------------------------------------------//


  {
    id: 55,
    title: 'Vídeo  - Efeitos da radiação solar',
    description: 'Nesse vídeo, é destacada a necessidade de comparar o efeito da radiação solar, como o aquecimento e a reflexão, em diferentes tipos de superfície, como água, areia, solo e superfícies escuras, claras e metálicas, entre outras. O vídeo busca enfatizar a importância desse estudo comparativo para compreendermos como a radiação solar interage com diferentes materiais e superfícies. Ao analisar o aquecimento e a reflexão em diferentes contextos, é possível obter um conhecimento mais abrangente sobre as propriedades dos materiais e sua interação com a energia solar, contribuindo para uma compreensão mais profunda dos processos de aquecimento e reflexão no ambiente.',
    image: 'https://i.ytimg.com/vi/r8g1Qbtgdik/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBzPEyt5_24BfS_potPLKcGUYqDHQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI08/efeitosdaradiacaosolar.mp4'
  },
  {
    id: 56,
    title: 'Quiz - Efeitos da radiação solar',
    description: 'O quiz sobre os efeitos da radiação solar e a comparação do seu impacto, como o aquecimento e a reflexão, em diferentes tipos de superfície, como água, areia, solo e superfícies escuras, claras e metálicas, reforça a aprendizagem teórica sobre esse tema. O quiz busca fortalecer o conhecimento teórico, incentivando a compreensão dos efeitos da radiação solar em diferentes materiais e superfícies. Ao comparar o aquecimento e a reflexão em contextos diversos, os participantes do quiz poderão ampliar sua compreensão dos processos relacionados à radiação solar e seus impactos no ambiente. Dessa forma, o quiz promove uma aprendizagem mais completa e aprofundada sobre os efeitos da radiação solar.',
    image: 'https://i.ytimg.com/vi/r8g1Qbtgdik/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBzPEyt5_24BfS_potPLKcGUYqDHQ',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI08'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI01 -------------------------------------------------------------------------------------------//
  {
    id: 57,
    title: 'Vídeo  - Aula Produção de Sons em Diferentes Meios',
    description: 'Nesse vídeo, são apresentadas as técnicas de produção de diferentes sons a partir da vibração de objetos variados, além de identificar as variáveis que influenciam nesse fenômeno. O vídeo explora a diversidade de sons que podem ser criados por meio da vibração de diferentes objetos e destaca a importância de compreender as variáveis envolvidas nesse processo.',
    image: 'https://i.ytimg.com/vi/gGlzRZLmXrw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBJQYnYbBjl23V3VYn1uEr7ND1QDw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI01/sonsesuascaracteristicas.mp4'
  },

  {
    id: 58,
    title: 'Vídeo  - O Som',
    description: 'Vídeo educativo para crianças com o qual vão aprender o que é o som e quais são suas características. O som é o fenômeno produzido quando as vibrações de um corpo se transmitem em um meio sólido, líquido ou gasoso até chegarem aos nossos ouvidos.  As fontes sonoras são aqueles objetos, elementos ou materiais que vibram produzindo sons. Podem ser de dois tipos: fontes sonoras naturais, como os trovões, ou fontes sonoras artificiais, como um instrumento musical.',
    image: 'https://i.ytimg.com/vi/gGlzRZLmXrw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBJQYnYbBjl23V3VYn1uEr7ND1QDw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/SOUND_PT.mp4'
  },

  {
    id: 59,
    title: 'Vídeo  - Sons dos instrumentos de cordas',
    description: 'Neste novo vídeo de música da Smile and Learn, as crianças podem aprender os instrumentos musicais de corda como o violino, a guitarra e o piano. Também podem escutar o som de cada um deles. Este vídeo faz parte de uma coleção de música e tem como objetivo que as crianças aprendam as famílias dos instrumentos musicais.',
    image: 'https://i.ytimg.com/vi/uoTEUigcC_I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAS8E2KxeAKW4myFZkGPqppo_qHIw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Instrumentos_Cuerda_PT.mp4'
  },

  {
    id: 60,
    title: 'Vídeo  - Sons dos instrumentos de percussão',
    description: 'Neste novo vídeo de música da Smile and Learn, as crianças podem aprender os instrumentos musicais de percussão, como o tambor e a bateria, por exemplo.',
    image: 'https://i.ytimg.com/vi/ARzu84TiI5g/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC_l6Q-FQBvv5zJT83xALeoNwzAgw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Instrumentos_Percusion_PT.mp4'
  },

  {
    id: 61,
    title: ' Vídeo  - Sons dos instrumentos de sopro',
    description: 'Neste novo vídeo de música da Smile and Learn, as crianças podem aprender os instrumentos musicais de sopro, tanto de sopro madeira, quanto de sopro metal.',
    image: 'https://i.ytimg.com/vi/dpMtPEpQZFw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDGU9tXu8H-3GFodfVOtEwlUFcW_A',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Instrumentos_Viento_PT.mp4'
  },

  {
    id: 62,
    title: 'Game - sons graves e agudos',
    description: 'Simon Says é um jogo interativo pensado para trabalhar a memória e o ouvido musical. Trabalhamos, graças a este jogo, a atenção das crianças e também as ensinamos a diferenciar os sons de acordo com a altura, ou seja, se são graves ou agudos.',
    image: 'https://i.ytimg.com/vi/gGlzRZLmXrw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBJQYnYbBjl23V3VYn1uEr7ND1QDw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/SIMON/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },

  {
    id: 63,
    title: 'Quiz -  Produção de Sons em Diferentes Meios',
    description: 'O quiz sobre a produção de diferentes sons a partir da vibração de objetos variados e a identificação das variáveis que influenciam nesse fenômeno busca reforçar o conhecimento teórico sobre esse assunto. O quiz tem como objetivo promover a compreensão das técnicas de produção de sons por meio da vibração de objetos e a identificação das variáveis que desempenham um papel nesse processo.',
    image: 'https://i.ytimg.com/vi/m44-YwF5-fc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAFb8d8zp2EVIG-KxxIRfdyR-QyUw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI0103'
  },

  //  ------------------------------------------------------------ Ciências - EF03CI02 -------------------------------------------------------------------------------------------//

  {
    id: 64,
    title: 'Vídeo  - O que é a luz?',
    description: 'Vídeo educativo para crianças com o qual vão aprender sobre a luz. A luz vem de fontes de luz que podem ser naturais ou artificiais, como o sol e um farol. Existem diferentes materiais que se comportam de forma diferente com a luz e estes podem ser transparentes, translúcidos e opacos. A luz pode passar através dos materiais transparentes. Os materiais translúcidos são aqueles que deixam passar só uma parte da luz e os opacos são aqueles que não permitem que a luz passe. As crianças também vão aprender as principais propriedades da luz: a reflexão e a refração. A reflexão é a propriedade que permite a visualização de objetos que não emitem luz. Ocorre quando os raios de luz atingem um objeto e depois refletem em outra direção.  A refração acontece quando os raios de luz passam de um meio para o outro, por exemplo, do ar para a água. Isso faz com que os raios mudem de velocidade e de direção.',
    image: 'https://i.ytimg.com/vi/1A4VMMS5lFM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBxb9bSap0qrk8wWpkyJSyUxWesPg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/LIGHT_PT.mp4'
  },

  {
    id: 65,
    title: 'Vídeo  - Propriedades da Luz',
    description: 'Vídeo educativo para crianças com o qual vão aprender quais são as principais propriedades da luz: a reflexão e a refração.  A reflexão é a propriedade que permite ver os  objetos que não emitem luz. Ocorre quando os raios de luz, chamados raios incidentes, atingem um objeto e depois refletem em outra direção, chamando-se, portanto, raios refletidos. Há dois tipos diferentes de reflexão: reflexão especular e reflexão difusa. A refração acontece quando os raios de luz passam de um meio para o outro, por exemplo do ar para a água, fazendo com que os raios mudem de velocidade e de direção. Os raios que chegam ao objeto são chamados de raios incidentes e os raios que mudam seu caminho ou velocidade são chamados de raios refratados. A refração também é a propriedade que permite que a luz branca se decomponha em cores diferentes.',
    image: 'https://i.ytimg.com/vi/tZMDOociHBY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCpTPVeJr7ERMqPSw2b5l9qSILGaQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/LIGHT2_PT.mp4'
  },

  {
    id: 66,
    title: 'Vídeo  - Propagação da luz ',
    description: 'Nesse vídeo, são apresentadas experiências que ocorrem com a passagem da luz através de objetos transparentes, como copos, janelas de vidro, lentes, prismas e água. Além disso, o vídeo aborda o comportamento da luz ao entrar em contato com superfícies polidas, como espelhos, e ao interagir com objetos opacos, como paredes, pratos, pessoas e outros objetos do cotidiano. Essas experiências visam proporcionar uma compreensão mais profunda dos efeitos da luz e como ela interage com diferentes materiais, superfícies e objetos, permitindo uma exploração fascinante do mundo óptico.',
    image: 'https://i.ytimg.com/vi/ORFbOu94QpA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCpJBSKhCbOH-Zl8Fszn2Ql9kBGnw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI02/propagacaodaluz.mp4'
  },
  {
    id: 67,
    title: 'Quiz - Propagação da Luz ',
    description: 'O quiz sobre a passagem da luz através de objetos transparentes, como copos, janelas de vidro, lentes, prismas e água, o contato com superfícies polidas, como espelhos, e a interação com objetos opacos, como paredes, pratos, pessoas e outros objetos do cotidiano, tem como objetivo reforçar a aprendizagem teórica sobre esse fenômeno. O quiz busca fortalecer o conhecimento teórico acerca da propagação da luz e como ela se comporta ao interagir com diferentes materiais e superfícies.',
    image: 'https://i.ytimg.com/vi/ORFbOu94QpA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCpJBSKhCbOH-Zl8Fszn2Ql9kBGnw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI02'
  },

  //  ------------------------------------------------------------ Ciências - EF03CI03 -------------------------------------------------------------------------------------------//
  {
    id: 68,
    title: 'Vídeo - Manutenção da Saúde Auditiva e Visual',
    description: 'Nesse vídeo, é destacada a importância de discutir os hábitos necessários para a manutenção da saúde auditiva e visual, levando em consideração as condições do ambiente em termos de som e luz. O vídeo aborda a importância de conscientizar sobre a preservação da saúde dos ouvidos e dos olhos, considerando o impacto do ambiente sonoro e luminoso.',
    image: 'https://i.ytimg.com/vi/TfC2AKRuh1Q/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBC1y4YKxEE7EerW3f1ZYcTIDsYfg',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI03/saudeauditivaevisual.mp4'
  },
  {
    id: 69,
    title: 'Vídeo - Como funciona a audição?',
    description: 'Vídeo educativo para crianças com o qual podem aprender como funciona o ouvido através da viagem do som. Aprenda onde estão os três ossinhos do ouvido e qual é a função da cóclea. Nós te damos alguns conselhos para cuidar do seu ouvido! Este vídeo faz parte de uma coleção de ciências para crianças, onde as crianças aprendem de uma forma divertida. Excelente recurso para o ensino fundamental.',
    image: 'https://i.ytimg.com/vi/TfC2AKRuh1Q/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBC1y4YKxEE7EerW3f1ZYcTIDsYfg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Oido_Funcionamiento_PT.mp4'
  },
  {
    id: 70,
    title: 'Vídeo - Como funciona a visão?',
    description: 'Vídeo educativo para crianças que explica o funcionamento da visão. As crianças vão descobrir para que servem as partes do olho: a pálpebra, a glândula lacrimal, a íris, o cristalino, a pupila, a retina e o nervo ótico. Além disso, elas vão entender como entra a luz no olho, como focamos os objetos e como deciframos as coisas que vemos. No fim, vão ver algumas recomendações para cuidar dos olhos. Recurso perfeito para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/TfC2AKRuh1Q/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBC1y4YKxEE7EerW3f1ZYcTIDsYfg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Vista_Funcionamiento_PT.mp4'
  },
  {
    id: 71,
    title: ' Quiz - Manutenção da Saúde Auditiva e Visual ',
    description: 'O quiz sobre a importância de discutir hábitos necessários para a manutenção da saúde auditiva e visual, considerando as condições do ambiente em termos de som e luz, reforça a aprendizagem teórica sobre esse tema. O quiz tem como objetivo fortalecer o conhecimento teórico acerca da importância de adotar hábitos saudáveis para a preservação da saúde auditiva e visual, levando em consideração as condições ambientais relacionadas ao som e à luz.',
    image: 'https://i.ytimg.com/vi/DixTALM_STM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCJPHegNFKL35Ec1RDdhqan-oi85g',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI03'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI04 -------------------------------------------------------------------------------------------//
  {
    id: 72,
    title: 'Vídeo - Animais da Fazenda',
    description: 'Vídeo educativo para crianças onde podem aprender vocabulário e descobrir os animais da fazenda, como a vaca, o cavalo, a ovelha, a galinha, o coelho, o burro, a cabra, o cachorro, o gato, o pato e muito mais. Você sabe como é o som do burro? E o da ovelha? Descubra isto e muito mais neste vídeo, além de outras curiosidades.Este vídeo faz parte de uma coleção de vocabulário para crianças, na qual aprendem os animais e os sons que eles fazem, de uma maneira divertida. Conteúdo perfeito para Educação Infantil e Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/e123lOibPN0/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDPt0_Jwd0QGJZjuV2gvx4YmDiD_A',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Animales_Granja_Vocabulario_PT.mp4'
  },
  {
    id: 73,
    title: 'Vídeo - Animais Selvagens',
    description: 'Vídeo educativo para crianças onde podem aprender vocabulário e descobrir os animais selvagens, como o leão, o elefante, a girafa, o macaco, o crocodilo, o gorila, a zebra, o tigre, o rinoceronte, o urso, o lobo e muito mais.',
    image: 'https://i.ytimg.com/vi/BLYr11fcC58/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDWrOHCAo_xDE5YM9RUK8mp3cYoFA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Animales_Salvajes_Vocabulario_PT.mp4'
  },
  {
    id: 74,
    title: 'Vídeo - Animais do Mar',
    description: 'Vídeo educativo para crianças onde podem aprender vocabulário e descobrir os animais aquáticos, como a foca, o golfinho, o tubarão, a tartaruga, a baleia, o peixe palhaço, o polvo, o caranguejo, o cavalo-marinho, a medusa e muito mais. Você sabe quantas patas o polvo tem? Descubra isto e muito mais neste vídeo, onde você vai aprender alguns dos animais aquáticos, além de outras curiosidades. Este vídeo faz parte de uma coleção de vocabulário para crianças, na qual aprendem os animais e os seus sons de uma maneira divertida. Conteúdo perfeito para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/cxmC8Mn8iE4/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA99l0khYcL7aVF4NWXOEjI6x69XA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Animales_Agua_Vocabulario_PT.mp4'
  },
  {
    id: 75,
    title: 'Vídeo - Animais Anfíbios',
    description: 'Vídeo educativo para crianças, com o qual vão descobrir outro grupo de animais vertebrados: os anfíbios. Estes vertebrados são ovíparos e podem viver tanto na água, quanto na terra. Também vão aprender como eles respiram, onde nascem e o que é a metamorfose, além de outras curiosidades.',
    image: 'https://i.ytimg.com/vi/a5YCne38E7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA1XuQZYIhHAF3IqlgZcALlZG4PdQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Anfibios_PT.mp4'
  },
  {
    id: 76,
    title: ' Game - Animais Anfíbios ',
    description: '“Anfíbios” é um jogo interativo para diferenciar os anfíbios, pelas características deles, dos outros animais vertebrados. Inclui um jogo e um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/a5YCne38E7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA1XuQZYIhHAF3IqlgZcALlZG4PdQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEAMPHIBIANS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 77,
    title: ' Quiz - Animais do Mundo',
    description: 'O quiz de Animais do mundo é um questionário interativo para avaliar a aprendizagem teórica vista no mesmo aplicativo. As perguntas estão direcionadas aos conceitos e definições que aparecem no mencionado aplicativo.',
    image: 'https://i.ytimg.com/vi/yFkpx-ICTPw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8S49Mlw9_SENnRCDtC25xi2MKeg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/MAPAMUNDIANIMALS_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 78,
    title: 'Game - Animais do Mundo - Atividade',
    description: 'Com Animais do mundo você saberá informações importantes sobre 90 animais de todo o mundo. Além disso, você poderá testar os seus conhecimentos em dois divertidos jogos de perguntas.',
    image: 'https://i.ytimg.com/vi/yFkpx-ICTPw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8S49Mlw9_SENnRCDtC25xi2MKeg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/MAPAMUNDIANIMALS_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 79,
    title: 'Vídeo - Aves',
    description: 'Vídeo educativo para crianças, onde vão descobrir algumas curiosidades sobre os animais ovíparos. Estes vertebrados nascem dos ovos, caminham sobre duas patas e têm asas que lhes permitem voar. Alimentam-se através do bico e têm o corpo coberto de penas. Entre as aves está a águia, o pinguim e o avestruz. Este vídeo faz parte de uma coleção sobre os animais. Recomendado para crianças do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/jvw1pS5DgKY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAG8HYEH0A_3fNx1TpVJJ58Nrqp7w',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Aves_PT.mp4'
  },
  {
    id: 80,
    title: 'Game - Aves ',
    description: '“Aves” é um jogo interativo para diferenciar as aves, pelas características delas, dos outros animais vertebrados. Inclui dois jogos de identificação das aves, de acordo com as características e sons. Além de um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/jvw1pS5DgKY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAG8HYEH0A_3fNx1TpVJJ58Nrqp7w',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEBIRDS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 81,
    title: 'Vídeo - Características e desenvolvimento dos animais.',
    description: 'Nesse vídeo, são apresentadas as principais características que envolvem a identificação do modo de vida dos animais, como sua alimentação, reprodução e forma de deslocamento. Além disso, o vídeo incentiva a comparação entre diferentes animais e a organização de grupos com base em características externas comuns, como a presença de penas, pelos, escamas, bico, garras, antenas e patas. Essas abordagens visam proporcionar uma compreensão mais profunda sobre os animais e sua diversidade, estimulando a observação e a classificação com base em características visíveis. Assim, o vídeo busca promover uma aprendizagem enriquecedora e interativa sobre o mundo animal.',
    image: 'https://i.ytimg.com/vi/gpnix8R3gXc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDjUExtEbtTGqkmA1WVirB-RqB9lQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI04-06/desenvolvimentosdosanimais1.mp4'
  },
  {
    id: 82,
    title: 'Quiz - Características e desenvolvimento dos animais',
    description: 'O quiz tem como objetivo reforçar a aprendizagem ao identificar as características do modo de vida dos animais, como sua alimentação, reprodução e forma de deslocamento. Além disso, o quiz busca promover a comparação entre diferentes animais e a organização de grupos com base em características externas compartilhadas, como a presença de penas, pelos, escamas, bico, garras, antenas e patas. Ao participar do quiz, as crianças terão a oportunidade de consolidar seu conhecimento sobre o mundo animal, desenvolvendo habilidades de identificação e classificação com base em características visíveis.',
    image: 'https://i.ytimg.com/vi/gpnix8R3gXc/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDjUExtEbtTGqkmA1WVirB-RqB9lQ',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI0406'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI05 -------------------------------------------------------------------------------------------//


  {
    id: 83,
    title: 'Vídeo  - Etapas da Vida',
    description: 'O objetivo do vídeo é promover uma melhor compreensão das fases de desenvolvimento e crescimento presentes em diferentes espécies. O vídeo busca oferecer uma apresentação abrangente das transformações que ocorrem ao longo da vida dos animais, desde o nascimento até a fase adulta, considerando as particularidades dos meios terrestres e aquáticos. Ao explorar essas alterações, o vídeo proporciona uma visão detalhada da diversidade da vida animal.',
    image: 'https://i.ytimg.com/vi/idrFpuEDD1I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDh6RtPDNV03wOfzCbD-ZJ44stEwQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI05/etapasdavida.mp4'
  },
  {
    id: 84,
    title: 'Vídeo  - Animais Ovíparos - Tartaruga',
    description: 'Vídeo educativo para crianças com o qual vão aprender características e curiosidades sobre as tartarugas. A tartaruga é um animal vertebrado, réptil, onívoro, que vive em todos os continentes. As tartarugas são animais ovíparos, ou seja, nascem dos ovos que as mães chocam em ninhos que cavam na terra. Tem um casco formado por muitos ossos, que serve de proteção contra os predadores. As tartarugas vivem muitos anos e estavam na Terra muito antes que nós.',
    image: 'https://i.ytimg.com/vi/EGkYCQ1cZ50/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDJxGWs3X3mW9oZuRnktFb5N636fw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/TORTUGA_PT.mp4'
  },
  {
    id: 85,
    title: 'Vídeo  - Animais Vivíparos - Elefante',
    description: 'Vídeo educativo para crianças com o qual vão aprender características e curiosidades sobre os elefantes. Os elefantes são animais vertebrados, mamíferos e herbívoros: comem raízes, folhas e a casca de algumas árvores. Você sabia que as fêmeas vivem em manadas com os seus filhotes e os machos vivem sozinhos?',
    image: 'https://i.ytimg.com/vi/cmeRNZJYELM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDQyxwZkN64JoBAaJG7Wg-R3TGLSQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/ELEFANTE_PT.mp4'
  },
  {
    id: 86,
    title: 'Vídeo  - Animais ovovivíparos - Tubarão',
    description: 'Vídeo educativo para crianças com o qual vão aprender características e curiosidades sobre os tubarões. Os tubarões são animais vertebrados e carnívoros, que vivem em mares e oceanos.',
    image: 'https://i.ytimg.com/vi/MpIn1Xy95Hk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLArAyNQuOF5mD_B93p3LTefE6VqFg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Tiburon_PT.mp4'
  },
  {
    id: 87,
    title: 'Quiz - Etapas da Vida',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre as fases de desenvolvimento e crescimento presentes em diferentes espécies. Ao participar do quiz, os alunos têm a oportunidade de consolidar seu conhecimento sobre as etapas do desenvolvimento e crescimento que ocorrem em variadas espécies, proporcionando uma compreensão mais abrangente e aprofundada sobre esse processo.',
    image: 'https://school-static.ip.tv/permanent/64c11227f7d0d5664df7aba2/full.png',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI05'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI06 -------------------------------------------------------------------------------------------//
  {
    id: 88,
    title: 'Vídeo  - Vertebrados',
    description: 'Vídeo educativo para crianças, onde descobrirão os animais vertebrados, como as aves, os peixes, os mamíferos, os répteis e os anfíbios e os animais invertebrados, como os artrópodes, os anelídeos, os celenterados, os moluscos, as esponjas e os equinodermes. É um resumo sobre os animais vertebrados e invertebrados, perfeito para aprender vocabulário. Recomendado para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/stTwpdrwWwM/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDxwwob7EyZPOgTjVrTcCJ5iW3s5Q',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Vertebrados_PT.mp4'
  },
  {
    id: 89,
    title: 'Vídeo  - Mamíferos',
    description: 'Vídeo educativo para crianças onde vão descobrir os animais mamíferos. Estes vertebrados ovíparos respiram através dos pulmões e são de sangue quente. A maioria deles têm o corpo coberto de pelo e vivem na terra e na água. Às vezes podem até voar. Entre os mamíferos estão os cachorros, os cavalos, as vacas e os humanos. Este vídeo faz parte de uma coleção sobre animais. Perfeito para crianças do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/4ojrOwCPkHQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCOCKO2d7DdIWEpuAY-dHsEpUgRDA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Mamiferos_PT.mp4'
  },
  {
    id: 90,
    title: 'Game - Mamíferos',
    description: '“Mamíferos” é um jogo interativo para diferenciar os mamíferos, pelas características deles, dos outros animais vertebrados. Inclui dois jogos de identificação dos animais mamíferos, de acordo com as características e sons. Além de um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/4ojrOwCPkHQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCOCKO2d7DdIWEpuAY-dHsEpUgRDA',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATESMAMMALS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 91,
    title: 'Vídeo  - Aves',
    description: 'Vídeo educativo para crianças, onde vão descobrir algumas curiosidades sobre os animais ovíparos. Estes vertebrados nascem dos ovos, caminham sobre duas patas e têm asas que lhes permitem voar. Alimentam-se através do bico e têm o corpo coberto de penas. Entre as aves está a águia, o pinguim e o avestruz. Este vídeo faz parte de uma coleção sobre os animais. Recomendado para crianças do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/jvw1pS5DgKY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAG8HYEH0A_3fNx1TpVJJ58Nrqp7w',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Aves_PT.mp4'
  },
  {
    id: 92,
    title: ' Game - Aves ',
    description: '“Aves” é um jogo interativo para diferenciar as aves, pelas características delas, dos outros animais vertebrados. Inclui dois jogos de identificação das aves, de acordo com as características e sons. Além de um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/jvw1pS5DgKY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAG8HYEH0A_3fNx1TpVJJ58Nrqp7w',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEBIRDS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 93,
    title: 'Vídeo  - Peixes',
    description: 'Vídeo educativo para crianças onde podem descobrir outro grupo de animais vertebrados: os peixes. Estes vertebrados são aquáticos e podem viver tanto em água doce, quanto em água salgada. Aprenda como respiram, como se movem na água e como é o esqueleto deles, além de outras curiosidades. Este vídeo faz parte de uma coleção sobre animais para crianças, com a qual aprendem de uma forma divertida. Perfeito para as aulas de ciências do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/Z_9VePVCyZk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBZnK5wgN-u0I6siZnCJeHhO7Ebyw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Peces_PT.mp4'
  },
  {
    id: 94,
    title: 'Game - Peixes ',
    description: '“Peixes” é um jogo interativo para diferenciar os peixes, pelas características deles, dos outros animais vertebrados. Inclui dois jogos de identificação dos peixes, de acordo com as características e sons. Além de um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/Z_9VePVCyZk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBZnK5wgN-u0I6siZnCJeHhO7Ebyw',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATESFISH/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 95,
    title: 'Vídeo  - Anfíbios',
    description: 'Vídeo educativo para crianças, com o qual vão descobrir outro grupo de animais vertebrados: os anfíbios. Estes vertebrados são ovíparos e podem viver tanto na água, quanto na terra. Também vão aprender como eles respiram, onde nascem e o que é a metamorfose, além de outras curiosidades.',
    image: 'https://i.ytimg.com/vi/a5YCne38E7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA1XuQZYIhHAF3IqlgZcALlZG4PdQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Anfibios_PT.mp4'
  },
  {
    id: 96,
    title: 'Game - Anfíbios',
    description: '“Anfíbios” é um jogo interativo para diferenciar os anfíbios, pelas características deles, dos outros animais vertebrados. Inclui um jogo e um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/a5YCne38E7s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA1XuQZYIhHAF3IqlgZcALlZG4PdQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEAMPHIBIANS/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 97,
    title: 'Vídeo - Répteis',
    description: 'Vídeo educativo para crianças onde vão descobrir outro grupo de animais vertebrados: os répteis. Estes vertebrados são ovíparos e o corpo deles está cheio de escamas. Aprendam tudo sobre os répteis: como respiram, onde vivem, quantos anos vivem e um monte de curiosidades. Este vídeo faz parte de uma coleção sobre animais para crianças, com a qual aprendem de uma forma divertida. Perfeito para as aulas de ciências do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/sTmXtRcJeqg/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCU3ceWkqVr6U-bkrTBu5CAnDcMmQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Reptiles_PT.mp4'
  },
  {
    id: 98,
    title: 'Game - Répteis ',
    description: '“Répteis” é um jogo interativo para diferenciar os anfíbios, pelas características deles, dos outros animais vertebrados. Inclui um jogo e um quiz para treinar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/sTmXtRcJeqg/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCU3ceWkqVr6U-bkrTBu5CAnDcMmQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VERTEBRATEREPTILES/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 99,
    title: 'Vídeo - Invertebrados ',
    description: 'Vídeo educativo para crianças onde elas vão descobrir os animais invertebrados, como os artrópodes, os anelídeos, os celenterados, os moluscos, as esponjas e os equinodermes. Podem aprender onde nascem, como vivem, como respiram e várias curiosidades. Vão conhecer, também, exemplos de cada grupo de invertebrados. Entre os animais invertebrados, estão: a medusa, a estrela do mar, a minhoca, o caracol e muito mais! Este video é uma recopilação sobre os animais invertebrados. Recomendado para alunos do Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/yVX9zgNW7fk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBeUnjRRglmLJNOS4-edgyuCsvu-w',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Invertebrados_PT.mp4'
  },
  {
    id: 100,
    title: 'Quiz - Classificando Animais',
    description: 'O quiz do reino animal é um questionário interativo para testar o aprendizado teórico da coleção de vídeos e aplicativos de animais. As perguntas estão focadas nas diferenças entre vertebrados e invertebrados.',
    image: 'https://i.ytimg.com/vi/yFkpx-ICTPw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8S49Mlw9_SENnRCDtC25xi2MKeg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/CLASSIFYANIMALS_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A=='
  },
  {
    id: 101,
    title: 'Vídeo - Características e desenvolvimento dos animais',
    description: 'Nesse vídeo, são apresentadas as principais características que envolvem a identificação do modo de vida dos animais, como sua alimentação, reprodução e forma de deslocamento. Além disso, o vídeo incentiva a comparação entre diferentes animais e a organização de grupos com base em características externas comuns, como a presença de penas, pelos, escamas, bico, garras, antenas e patas. Essas abordagens visam proporcionar uma compreensão mais profunda sobre os animais e sua diversidade, estimulando a observação e a classificação com base em características visíveis. Assim, o vídeo busca promover uma aprendizagem enriquecedora e interativa sobre o mundo animal.',
    image: 'https://i.ytimg.com/vi/yFkpx-ICTPw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8S49Mlw9_SENnRCDtC25xi2MKeg',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI04-06/desenvolvimentosdosanimais1.mp4'
  },
  {
    id: 102,
    title: 'Quiz - Características e desenvolvimento dos animais',
    description: 'O quiz tem como objetivo reforçar a aprendizagem ao identificar as características do modo de vida dos animais, como sua alimentação, reprodução e forma de deslocamento. Além disso, o quiz busca promover a comparação entre diferentes animais e a organização de grupos com base em características externas compartilhadas, como a presença de penas, pelos, escamas, bico, garras, antenas e patas. Ao participar do quiz, as crianças terão a oportunidade de consolidar seu conhecimento sobre o mundo animal, desenvolvendo habilidades de identificação e classificação com base em características visíveis.',
    image: 'https://i.ytimg.com/vi/yFkpx-ICTPw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD8S49Mlw9_SENnRCDtC25xi2MKeg',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI0406'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI07 -------------------------------------------------------------------------------------------//

  {
    id: 103,
    title: 'Vídeo - O Planeta Terra',
    description: 'Vídeo educativo para aprender as quatro camadas da Terra: a atmosfera, a biosfera, a hidrosfera e a geosfera. Descubra do que são formadas, onde ficam e porque as diferentes camadas da Terra são importantes. Você vai descobrir a diferença entre as camadas externas e as camadas internas, em que camada encontramos o oxigênio e em qual delas vivemos.',
    image: 'https://i.ytimg.com/vi/U1fiMU8fbcE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjSiqfVcu6Jb_hrWjcuZV4Z-wtXw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Tierra_PT.mp4'
  },
  {
    id: 104,
    title: 'Vídeo - Camadas da Terra',
    description: 'Vídeo educativo com o qual as crianças vão aprender as camadas da Terra: a atmosfera, a biosfera, a hidrosfera e a geosfera. A atmosfera é a camada externa da Terra e é dividida em 5 camadas diferentes: a troposfera, a estratosfera, a mesosfera, a termosfera e a exosfera. Depois dela há mais duas camadas: a biosfera e a hidrosfera. A biosfera é a camada onde se encontram os seres vivos e os ecossistemas (os bosques, as florestas, a savana, o deserto e a tundra). A hidrosfera é composta de água e cobre três quartos do planeta. Por último temos a geosfera, que é dividida em crosta, manto e núcleo.',
    image: 'https://i.ytimg.com/vi/MKlj6wCdwrA/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBBezjClUukxdAz_6iHSwnuSqzEQw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Capas_Tierra_PT.mp4'
  },
  {
    id: 105,
    title: 'Vídeo - Geosfera',
    description: 'Vídeo educativo sobre a geosfera. O que é a geosfera? A geosfera é a camada interna da Terra e vai da superfície até o centro do planeta. Aprenda as características da geosfera e as suas camadas: a crosta terrestre, o manto terrestre e o núcleo da Terra. Este vídeo faz parte de uma coleção de ciências para crianças e é ideal para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/sd9GcZpXZ7k/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD_Rx3f6fU5BdOQoJcXsf9LAQ1rsA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Geosfera_PT.mp4'
  },
  {
    id: 106,
    title: 'Quiz - Geosfera ',
    description: 'O quiz sobre a geosfera é um jogo pensado para que as crianças reforcem a aprendizagem dos conhecimentos sobre a geosfera.',
    image: 'https://i.ytimg.com/vi/sd9GcZpXZ7k/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD_Rx3f6fU5BdOQoJcXsf9LAQ1rsA',
    AssetsLink: 'O quiz sobre a geosfera é um jogo pensado para que as crianças reforcem a aprendizagem dos conhecimentos sobre a geosfera.'
  },
  {
    id: 107,
    title: 'Vídeo - Características da Terra',
    description: 'O vídeo tem como objetivo auxiliar na identificação das características da Terra, como seu formato esférico e a presença de elementos como água e solo. Isso é realizado por meio da observação, manipulação e comparação de diferentes formas de representação do planeta, como mapas, globos e fotografias. O vídeo busca fornecer uma compreensão mais ampla e prática sobre as características da Terra, permitindo aos alunos explorar e analisar diversas representações para obter um conhecimento mais completo do nosso planeta.',
    image: 'https://i.ytimg.com/vi/U1fiMU8fbcE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjSiqfVcu6Jb_hrWjcuZV4Z-wtXw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI07/caracteristicasdaterra.mp4'
  },
  {
    id: 108,
    title: 'Quiz - Características da Terra',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre as características da Terra, como seu formato esférico e a presença de elementos como água e solo. Ao participar do quiz, os alunos têm a oportunidade de consolidar seu conhecimento sobre as características fundamentais da Terra, desenvolvendo habilidades de observação, análise e comparação para obter uma compreensão mais aprofundada e abrangente do nosso planeta.',
    image: 'https://i.ytimg.com/vi/U1fiMU8fbcE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjSiqfVcu6Jb_hrWjcuZV4Z-wtXw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI07'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI08 -------------------------------------------------------------------------------------------//

  {
    id: 109,
    title: 'Vídeo - Movimentos de rotação e translação da Terra',
    description: 'Vídeo educativo para crianças, com o qual vão aprender os movimentos de rotação e translação do planeta Terra. Descubra como é cada movimento, quanto tempo a Terra demora para dar uma volta completa ao redor dela mesma, porque temos as estações do ano e os anos bissextos e outras curiosidades.',
    image: 'https://i.ytimg.com/vi/TUy6SC2MRig/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAyDwBmhPPaJpPzzGFJvyi90NRZ2w',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Rotacion_Traslacion_PT.mp4'
  },
  {
    id: 110,
    title: 'Vídeo  - Movimento Aparente do Sol',
    description: 'Nesse vídeo, são apresentadas as diferentes posições do Sol ao longo do dia, associando-as ao tamanho da sombra projetada. Além disso, destaca-se a importância de observar, identificar e registrar os períodos diários em que o Sol, outras estrelas, a Lua e os planetas são visíveis no céu. Esse vídeo visa promover a compreensão sobre as variações da posição do Sol, bem como incentivar a observação e o registro dos fenômenos celestes, permitindo uma maior compreensão do nosso ambiente e do Sistema Solar.',
    image: 'https://i.ytimg.com/vi/r8g1Qbtgdik/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBzPEyt5_24BfS_potPLKcGUYqDHQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF02CI07-EF03CI08/periodosdodia.mp4'
  },
  {
    id: 111,
    title: 'Quiz - Períodos do Dia',
    description: 'O quiz sobre as posições do Sol em diversos horários do dia, associando-as ao tamanho da sombra projetada, assim como a importância de observar, identificar e registrar os períodos diários em que o Sol, outras estrelas, a Lua e os planetas são visíveis no céu, reforça a aprendizagem teórica sobre a importância desses fenômenos celestes. O quiz busca fortalecer o conhecimento teórico, incentivando a compreensão da relação entre as posições do Sol, a projeção das sombras e os períodos de visibilidade dos astros no céu. Através desse aprendizado, é possível obter uma compreensão mais ampla do nosso ambiente, bem como do funcionamento do Sistema Solar.',
    image: 'https://i.ytimg.com/vi/TUy6SC2MRig/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAyDwBmhPPaJpPzzGFJvyi90NRZ2w',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF02CI0708'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI09 -------------------------------------------------------------------------------------------//

  {
    id: 112,
    title: 'Vídeo  - Características do Solo',
    description: 'Nesse vídeo, são apresentadas as características de diferentes amostras de solo do entorno da escola, levando em consideração atributos como cor, textura, cheiro, tamanho das partículas e permeabilidade. O vídeo busca explorar as características distintas dessas amostras de solo, fornecendo uma compreensão mais aprofundada das propriedades e qualidades presentes em cada uma delas. Ao destacar essas características, o vídeo visa promover a conscientização sobre a diversidade do solo e a importância de compreender suas características para a agricultura, jardinagem e preservação ambiental.',
    image: 'https://i.ytimg.com/vi/QHIQaD5lWzo/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLALXzw_mapr58eDJdOFSJmBnCCdAg',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI09/caracteristicasdosolo.mp4'
  },
  {
    id: 113,
    title: ' Quiz - Características do solo ',
    description: 'O quiz busca reforçar a aprendizagem sobre as características de diferentes amostras de solo do entorno da escola, levando em consideração atributos como cor, textura, cheiro, tamanho das partículas e permeabilidade.',
    image: 'https://i.ytimg.com/vi/QHIQaD5lWzo/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLALXzw_mapr58eDJdOFSJmBnCCdAg',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI09'
  },
  //  ------------------------------------------------------------ Ciências - EF03CI10 -------------------------------------------------------------------------------------------//

  {
    id: 114,
    title: ' Vídeo - Uso do Solo ',
    description: 'O vídeo tem como objetivo apresentar e identificar os diferentes usos do solo, como plantação e extração de materiais, entre outras possibilidades. O vídeo busca promover o reconhecimento da importância do solo tanto para a agricultura quanto para a vida em geral.',
    image: 'https://i.ytimg.com/vi/4-xcJjiSNHQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDmfByh45c-v33YQtNK3JduzKtdLw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF03CI10/usosdosolo.mp4',
  },
  {
    id: 115,
    title: 'Quiz -  Uso do Solo  ',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre os diferentes usos do solo, como plantação e extração de materiais, entre outras possibilidades. O questionário promove o reconhecimento da importância do solo tanto para a agricultura quanto para a vida em geral.',
    image: 'https://i.ytimg.com/vi/4-xcJjiSNHQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDmfByh45c-v33YQtNK3JduzKtdLw',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF03CI10',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI01 -------------------------------------------------------------------------------------------//

  {
    id: 116,
    title: 'Vídeo - Aula Misturas do Cotidiano ',
    description: 'O vídeo tem como objetivo identificar misturas presentes na vida diária, com base em suas propriedades físicas observáveis, promovendo o reconhecimento da sua composição. O vídeo busca fornecer exemplos práticos e visuais de misturas encontradas em nosso cotidiano, permitindo aos alunos identificar e compreender a natureza das substâncias combinadas.',
    image: 'https://i.ytimg.com/vi/mcCXY-N8CXs/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCFgQ1Ws5DghZb44BDil5vyLqpY9w',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI01/misturasnocotidiano.mp4',
  },
  {
    id: 117,
    title: 'Quiz  - Misturas do Cotidiano ',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre as misturas presentes na vida diária,  com base em suas propriedades físicas observáveis, promovendo o reconhecimento da sua composição.',
    image: 'https://i.ytimg.com/vi/mcCXY-N8CXs/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCFgQ1Ws5DghZb44BDil5vyLqpY9w',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI01',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI02 -------------------------------------------------------------------------------------------//

  {
    id: 118,
    title: 'Vídeo - Estados da Matéria ',
    description: 'Vídeo educativo para crianças, com o qual vão aprender as mudanças de estado da matéria: Fusão, Vaporização, Condensação e Solidificação. Descubra isto e muito mais neste vídeo de Ciências para crianças, que faz parte de uma coleção sobre os Estados da Matéria, para aprender todas as mudanças e processos. Ideal para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/-J_qZlJOy24/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAg25dkuTQlzhrng4CAbcT1PEV55g',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Estados_Materia_PT.mp4',
  },
  {
    id: 119,
    title: 'Game - Estados da Matéria ',
    description: 'Estados da matéria é um jogo para aprender as mudanças de estado, produzidos pelo efeito da temperatura. As crianças vão aprender como acontecem as mudanças de estado da matéria através de experiências práticas, com objetos cotidianos. Fusão, Vaporização, Condensação e Solidificação, Líquido e muito mais. É uma atividade de Ciências ideal para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/-J_qZlJOy24/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAg25dkuTQlzhrng4CAbcT1PEV55g',
    AssetsLink: 'https://iptv.smileandlearn.com/game/STATESMATTER/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 120,
    title: 'Vídeo - Transformações reversíveis e irreversíveis ',
    description: 'O vídeo tem como objetivo testar e relatar as transformações nos materiais do dia a dia quando expostos a diferentes condições, como aquecimento, resfriamento, luz e umidade. O vídeo busca evidenciar que algumas mudanças causadas por aquecimento ou resfriamento são reversíveis, como as mudanças de estado físico da água, enquanto outras não são reversíveis, como o cozimento do ovo e a queima do papel. Ao explorar essas transformações, o vídeo proporciona uma compreensão mais profunda das propriedades dos materiais e das mudanças que ocorrem em resposta a diferentes condições. Dessa forma, o vídeo promove uma reflexão sobre a reversibilidade das transformações e incentiva uma abordagem científica para a compreensão dos processos que ocorrem no nosso cotidiano.',
    image: 'https://i.ytimg.com/vi/idrFpuEDD1I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDh6RtPDNV03wOfzCbD-ZJ44stEwQ',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI02-03/transformacoesreversiveiseirreversiveis.mp4',
  },
  {
    id: 121,
    title: ' Quiz - Transformações reversíveis e irreversíveis ',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre as transformações nos materiais do dia a dia quando expostos a diferentes condições, como aquecimento, resfriamento, luz e umidade. Ao participar do quiz, os usuários têm a oportunidade de consolidar seu conhecimento sobre as mudanças que ocorrem nos materiais em resposta a essas condições.',
    image: 'https://school-static.ip.tv/permanent/64c11227f7d0d5664df7aba2/full.png',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI0203',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI03 -------------------------------------------------------------------------------------------//

  {
    id: 122,
    title: 'Vídeo - Ciclo da água',
    description: 'Vídeo educativo para crianças, com o qual vão aprender o ciclo da água e quais são as etapas de: evaporação, condensação e precipitação. Descubra qual é a função do sol, como as nuvens se formam, por que se formam as gotas de chuva e muito mais. Este vídeo faz parte de uma coleção de ciências para crianças, no qual vão aprender conceitos relacionados e muito vocabulário. Vídeo perfeito para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/KZTRy6KL7_c/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCZZ9uIf9Sy4OBBZV367zOlVeHIzw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Ciclo_Agua_PT.mp4',
  },
  {
    id: 123,
    title: 'Quiz - Transformações reversíveis e não reversíveis',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre as transformações nos materiais do dia a dia quando expostos a diferentes condições, como aquecimento, resfriamento, luz e umidade. Ao participar do quiz, os usuários têm a oportunidade de consolidar seu conhecimento sobre as mudanças que ocorrem nos materiais em resposta a essas condições.',
    image: 'https://i.ytimg.com/vi/WXAst3FkBSE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBUNShlxjYJCEMprz_bMrTXyypAuw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI0203',
  },
  {
    id: 124,
    title: ' Vídeo -  Transformações reversíveis e não reversíveis',
    description: 'O vídeo tem como objetivo testar e relatar as transformações nos materiais do dia a dia quando expostos a diferentes condições, como aquecimento, resfriamento, luz e umidade. O vídeo busca evidenciar que algumas mudanças causadas por aquecimento ou resfriamento são reversíveis, como as mudanças de estado físico da água, enquanto outras não são reversíveis, como o cozimento do ovo e a queima do papel. Ao explorar essas transformações, o vídeo proporciona uma compreensão mais profunda das propriedades dos materiais e das mudanças que ocorrem em resposta a diferentes condições. Dessa forma, o vídeo promove uma reflexão sobre a reversibilidade das transformações e incentiva uma abordagem científica para a compreensão dos processos que ocorrem no nosso cotidiano.',
    image: 'https://i.ytimg.com/vi/WXAst3FkBSE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBUNShlxjYJCEMprz_bMrTXyypAuw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI02-03/transformacoesreversiveiseirreversiveis.mp4',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI04 e EF04CI05 -------------------------------------------------------------------------------------------//

  {
    id: 125,
    title: 'Vídeo - Alimentação de heterótrofos ',
    description: 'Vídeo educativo para crianças com o qual vão aprender quais são os organismos heterótrofos. Um ser vivo heterótrofo é aquele que não pode elaborar seu próprio alimento e que para sobreviver precisa alimentar-se de outros organismos. De acordo com a sua alimentação, os seres vivos heterótrofos classificam- se em três grupos: animais carnívoros, animais herbívoros e animais onívoros.',
    image: 'https://i.ytimg.com/vi/cL0Fe2JPhnY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBcISUKj5Pw216yxyhFVG5oq8Vs0A',
    AssetsLink: 'https://iptv.smileandlearn.com/video/EN/Alimentacion_Heterotrofos_EN.mp4',
  },
  {
    id: 126,
    title: 'Quiz - Alimentação de heterótrofos ',
    description: 'O quiz de alimentação de Heterótrofos é um questionário interativo para testar a aprendizagem teórica da coleção de aplicativos e vídeos de animais e nutrição. As perguntas estão dirigidas às diferenças entre a alimentação dos heterótrofos.',
    image: 'https://i.ytimg.com/vi/cL0Fe2JPhnY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBcISUKj5Pw216yxyhFVG5oq8Vs0A',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HETEROTROPHICFEEDING_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 127,
    title: 'Game - Alimentação de heterótrofos - Atividade ',
    description: 'Alimentação de heterótrofos é um jogo interativo para identificar e diferenciar os animais carnívoros, herbívoros e onívoros pelas suas características. Inclui um jogo de classificação dos animais heterótrofos de acordo com a sua alimentação para praticar o que foi aprendido.',
    image: 'https://i.ytimg.com/vi/cL0Fe2JPhnY/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBcISUKj5Pw216yxyhFVG5oq8Vs0A',
    AssetsLink: 'https://iptv.smileandlearn.com/game/HETEROTROPHICFEEDING_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 128,
    title: 'Vídeo - Cadeia Alimentar - plantas',
    description: 'Vídeo educativo para crianças sobre a fotossíntese das plantas. Aprenda como elas conseguem o alimento através da fotossíntese, como é o seu ciclo e qual é a diferença entre a seiva bruta e a seiva elaborada.',
    image: 'https://i.ytimg.com/vi/KOgk3YhJfdE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAFHyxd1xa24OWwE7-4BFEag6jQGA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Fotosintesis_PT.mp4',
  },
  {
    id: 129,
    title: 'Game - Nutrição da Planta ',
    description: 'Nutrição da Planta é um jogo interativo para identificar e conhecer como se alimentam as plantas pela fotossíntese e o processo de respiração. O jogo inclui um quebra-cabeça de sequencias, para organizar as fases dos processos implicados na nutrição. Além disso, podemos praticar o que aprendemos com um quiz.',
    image: 'https://i.ytimg.com/vi/BXJk6ngNnVw/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBLz5sgFxS2YUrGvczMVExV8qN78Q',
    AssetsLink: 'https://iptv.smileandlearn.com/game/PLANTNUTRITION/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 130,
    title: 'Vídeo - Mudança Climática',
    description: 'Vídeo educativo para crianças com o qual vão aprender o que é a mudança climática e o que podemos fazer para cuidar melhor do nosso planeta. Nos últimos séculos, o clima de toda a Terra mudou, devido à ação dos seres humanos e é cada vez mais comum a escassez de água, a desertificação, o desaparecimento de lagos e, inclusive, a subida do nível do mar. Tudo isso é consequência do aquecimento global, ou seja, do aumento da temperatura do planeta. Neste vídeo, propomos seis medidas para cuidar melhor do nosso planeta, que incluem o uso de energias renováveis, o uso do transporte público, a redução do uso de plástico, o cuidado dos seres vivos, a reciclagem e a economia de energia.',
    image: 'https://i.ytimg.com/vi/PH5halrNnfI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjn9jeacuwK9HHNAOTR6sdR7JAtQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Cambio_Climatico_PT.mp4',
  },
  {
    id: 131,
    title: 'Quiz  - Mudança Climática ',
    description: 'O quiz sobre a mudança climática é um questionário interativo para reforçar a aprendizagem teórica da atividade de mudança climática. As perguntas tem como objetivo fazer com que as crianças conheçam e aprendam o fenômeno da mudança climática.',
    image: 'https://i.ytimg.com/vi/PH5halrNnfI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAjn9jeacuwK9HHNAOTR6sdR7JAtQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/CLIMATECHANGE_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 132,
    title: ' Vídeo- Cadeia alimentar',
    description: 'O vídeo tem como objetivo analisar e construir cadeias alimentares simples, reconhecendo a posição ocupada pelos seres vivos nessas cadeias e destacando o papel do Sol como fonte primária de energia na produção de alimentos. Além disso, o vídeo tem o intuito de descrever e ressaltar as semelhanças e diferenças entre o ciclo da matéria e o fluxo de energia entre os componentes vivos e não vivos de um ecossistema.',
    image: 'https://i.ytimg.com/vi/jZON_wFSk_E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAt0XsrN7c2NL0P1UF2Tge7E3oxJw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI04-05/cadeiaalimentar.mp4',
  },
  {
    id: 133,
    title: 'Quiz - Cadeia alimentar ',
    description: 'O quiz tem como objetivo reforçar a aprendizagem sobre a análise e construção de cadeias alimentares simples, reconhecendo a posição ocupada pelos seres vivos nessas cadeias e destacando o papel fundamental do Sol como fonte primária de energia na produção de alimentos. Ao participar do quiz, os alunos têm a oportunidade de consolidar seu conhecimento sobre as interações alimentares entre os seres vivos e compreender a importância do fluxo de energia nesses sistemas.',
    image: 'https://i.ytimg.com/vi/jZON_wFSk_E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAt0XsrN7c2NL0P1UF2Tge7E3oxJw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI0405',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI06 e EF04CI07 -------------------------------------------------------------------------------------------//

  {
    id: 134,
    title: 'Vídeo - Bactérias ',
    description: 'Vídeo educativo para crianças no qual vão aprender o que são bactérias. As bactérias são microrganismos, por isso não podem ser vistas a olho nu e precisamos da ajuda de um microscópio. As principais características das bactérias são: são seres vivos, são organismos unicelulares, são células procariontes (sem núcleo) e podem viver em qualquer ambiente da Terra. Graças a este vídeo, as crianças vão descobrir a estrutura e as partes das bactérias: o flagelo, a membrana celular, os ribossomos, o citoplasma e o nucleóide. Também vão aprender alguns tipos de bactérias: bacilos, cocos e espirilos e saber como são transmitidas, como se comportam e que efeito os antibióticos produzem sobre elas.',
    image: 'https://i.ytimg.com/vi/1d7a8vflz7U/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBAxl0Y2lTSBQ0lxzDUVGlHkR7VnQ',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Bacteria_PT.mp4',
  },
  {
    id: 135,
    title: 'Quiz - Bacterias',
    description: 'O quiz de bactérias é um questionário interativo para reforçar a aprendizagem teórica sobre as características das bactérias.',
    image: 'https://i.ytimg.com/vi/1d7a8vflz7U/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBAxl0Y2lTSBQ0lxzDUVGlHkR7VnQ',
    AssetsLink: 'https://iptv.smileandlearn.com/game/BACTERIA_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 136,
    title: 'Vídeo - Fungos ',
    description: 'Vídeo educativo para crianças no qual vão aprender o que são e como os fungos são classificados. O reino dos fungos, também chamado de fungi, é um dos 5 reinos nos quais os seres vivos podem ser agrupados. Os fungos não são animais, nem vegetais, mas são organismos vivos, já que realizam as funções vitais de relação, reprodução e nutrição por si mesmos. Alguns fungos são microrganismos unicelulares e outros são pluricelulares. Ao longo do vídeo, as crianças vão descobrir qual é a estrutura dos fungos (uma ou várias células eucariontes), onde podem encontrá-los (nos alimentos, na pele, nos pulmões, ou até em substâncias antibióticas), como são transmitidos (principalmente por contato) e como eles se alimentam (são heterotróficos, ou seja, se alimentam de outros seres vivos).',
    image: 'https://i.ytimg.com/vi/WDdpPw2hHXU/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCMPwKIFcNVWtD_kM2q010V2BX6eg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Hongos_PT.mp4',
  },
  {
    id: 137,
    title: 'Vídeo - Fungos',
    description: 'O quiz de fungos é um questionário interativo para testar a aprendizagem teórica sobre as características dos fungos.',
    image: 'https://i.ytimg.com/vi/WDdpPw2hHXU/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCMPwKIFcNVWtD_kM2q010V2BX6eg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/FUNGI_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 138,
    title: ' Vídeo - Microrganismos',
    description: 'Vídeo educativo para crianças com o qual vão aprender o que são microrganismos e quais são os tipos que existem. Os microrganismos podem ser unicelulares ou pluricelulares, dependendo do número de células. Neste vídeo, os pequenos vão aprender quando foram descobertos, como podemos observá-los, e os três tipos nos quais são divididos: bactérias, vírus e fungos. Os microrganismos podem estar em todos os lugares. Para evitar doenças, é muito importante cuidar dos hábitos de higiene.',
    image: 'https://i.ytimg.com/vi/02tP_8vzpYQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE_dC5iLvB7A01lL-pkWw-FnjWlg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Microorganismos_PT.mp4',
  },
  {
    id: 139,
    title: 'Quiz - Microrganismos',
    description: 'O quiz de microrganismos é um questionário interativo para reforçar a aprendizagem teórica sobre as bactérias, os fungos e os vírus. Através do quiz, o conhecimento adquirido com o vídeo de microrganismos é reforçado.',
    image: 'https://i.ytimg.com/vi/02tP_8vzpYQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE_dC5iLvB7A01lL-pkWw-FnjWlg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/MICROORGANISM_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 140,
    title: 'Vídeo - Fungos e bactérias ',
    description: 'Nesse vídeo, os alunos terão a oportunidade de relacionar a participação de fungos e bactérias no processo de decomposição, reconhecendo a importância ambiental desse processo.',
    image: 'https://i.ytimg.com/vi/hGczB9YvI0s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAFObhPUk3nX1wYU0y43pDkzkPVqA',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI06/fungosebacterias.mp4',
  },
  {
    id: 141,
    title: ' Vídeo - Microrganismos  ',
    description: 'O vídeo tem como objetivo apresentar a participação dos microrganismos na produção de alimentos, combustíveis, medicamentos e outros produtos importantes. O vídeo explora o papel fundamental dos microrganismos, como bactérias e fungos, na produção de uma ampla variedade de recursos que são essenciais para nossa vida cotidiana.',
    image: 'https://i.ytimg.com/vi/02tP_8vzpYQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE_dC5iLvB7A01lL-pkWw-FnjWlg',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI07/microrganismos.mp4',
  },
  {
    id: 142,
    title: ' Quiz - Fungos e bactérias ',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica e relacionar a participação de fungos e bactérias no processo de decomposição, reconhecendo a importância ambiental desse processo.',
    image: 'https://i.ytimg.com/vi/hGczB9YvI0s/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAFObhPUk3nX1wYU0y43pDkzkPVqA',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI06',
  },
  {
    id: 143,
    title: ' Quiz - Microrganismos  ',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre a participação dos microrganismos na produção de alimentos, combustíveis, medicamentos e outros produtos importantes.',
    image: 'https://i.ytimg.com/vi/02tP_8vzpYQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE_dC5iLvB7A01lL-pkWw-FnjWlg',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI07',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI08 -------------------------------------------------------------------------------------------//

  {
    id: 144,
    title: 'Vídeo - Virus',
    description: 'Vídeo educativo para crianças com o qual vão aprender o que são os vírus e como são transmitidos. Os vírus são microrganismos, não podem ser vistos a olho nu, mas sim com a ajuda de um microscópio. Você sabia que os vírus não são seres vivos? Eles não podem realizar a função vital de reprodução por conta própria, diferente das células. Com este vídeo, as crianças vão descobrir a estrutura do vídeo (capsídeo e material genético) e o seu modo de transmissão. Também vão aprender o que são e para que servem as vacinas e poderão ver alguns exemplos de doenças causadas pelos vírus.',
    image: 'https://i.ytimg.com/vi/I5mRSKQoUkE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCs6lDP7-mb-fuqtKEe9RNJsoaVyA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Virus_PT.mp4',
  },
  {
    id: 145,
    title: 'Quiz - Virus',
    description: 'O quiz de vírus é um questionário interativo para reforçar a aprendizagem teórica da coleção de aplicativos e vídeos sobre vírus.',
    image: 'https://i.ytimg.com/vi/I5mRSKQoUkE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCs6lDP7-mb-fuqtKEe9RNJsoaVyA',
    AssetsLink: 'https://iptv.smileandlearn.com/game/VIRUS_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 146,
    title: ' Vídeo - Vírus e Bactérias',
    description: 'Vídeo educativo para crianças com o qual vão aprender a diferenciar os vírus e as bactérias. Os vírus e as bactérias têm algumas coisas em comum e muitas diferenças. Os dois são patogênicos, produzem doenças e são transmitidos facilmente através do que chamamos de vetores. As bactérias são células procariontes e são consideradas organismos vivos. Os vírus, por outro lado, precisam de um hóspede para poderem se reproduzir. Por isso, são considerados organismos não vivos. Outras diferenças? Os vírus são muito menores que as bactérias, têm uma estrutura diferente e os tratamentos para as doenças que cada um provoca são diferentes.',
    image: 'https://i.ytimg.com/vi/1HTvCjPk0xk/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCwSghnnhSTSMUFXtOYB6-f2Ox4Ow',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Virus_vs_Bacteria_PT.mp4',
  },
  {
    id: 147,
    title: 'Vídeo - Doenças causadas por microrganismos ',
    description: 'O vídeo tem como objetivo propor, a partir do conhecimento das formas de transmissão de alguns microrganismos, como vírus, bactérias e protozoários, atitudes e medidas adequadas para prevenção de doenças associadas a eles. O vídeo explora as diferentes formas de transmissão desses microrganismos e destaca a importância de adotar medidas preventivas para reduzir o risco de infecções e doenças.',
    image: 'https://i.ytimg.com/vi/02tP_8vzpYQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE_dC5iLvB7A01lL-pkWw-FnjWlg',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI08/doencascausadaspormicrorganismos.mp4',
  },
  {
    id: 149,
    title: 'Quiz - Doenças causadas por microrganismos ',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre as formas de transmissão de alguns microrganismos, como vírus, bactérias e protozoários, atitudes e medidas adequadas para prevenção de doenças associadas a eles.',
    image: 'https://i.ytimg.com/vi/02tP_8vzpYQ/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAE_dC5iLvB7A01lL-pkWw-FnjWlg',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI08',
  },

  //  ------------------------------------------------------------ Ciências - EF04CI09 e EF04CI10 -------------------------------------------------------------------------------------------//
  {
    id: 149,
    title: 'Vídeo - O movimento da Terra ',
    description: 'Vídeo educativo para crianças, com o qual vão aprender os movimentos de rotação e translação do planeta Terra. Descubra como é cada movimento, quanto tempo a Terra demora para dar uma volta completa ao redor dela mesma, porque temos as estações do ano e os anos bissextos e outras curiosidades.',
    image: 'https://i.ytimg.com/vi/TUy6SC2MRig/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAyDwBmhPPaJpPzzGFJvyi90NRZ2w',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Rotacion_Traslacion_PT.mp4',
  },
  {
    id: 150,
    title: ' Vídeo  - Pontos Cardeais ',
    description: 'O vídeo tem como objetivo identificar os pontos cardeais, com base no registro de diferentes posições relativas do Sol e da sombra de uma vara (gnômon), além de comparar as indicações dos pontos cardeais obtidas por meio da observação das sombras de uma vara (gnômon) com aquelas obtidas por meio de uma bússola.',
    image: 'https://i.ytimg.com/vi/70ot-v_I1_I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCKB0UjNJ5B6lFecOVLD18FPVmBpg',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI09-10/pontoscardeais.mp4',
  },
  {
    id: 151,
    title: ' Quiz  - Pontos Cardeais',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre os os pontos cardeais, com base no registro de diferentes posições relativas do Sol e da sombra de uma vara (gnômon), além de comparar as indicações dos pontos cardeais obtidas por meio da observação das sombras de uma vara (gnômon) com aquelas obtidas por meio de uma bússola.',
    image: 'https://i.ytimg.com/vi/70ot-v_I1_I/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCKB0UjNJ5B6lFecOVLD18FPVmBpg',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI0910',
  },
  //  ------------------------------------------------------------ Ciências - EF04CI11 -------------------------------------------------------------------------------------------//

  {

    id: 152,
    title: ' Vídeo  - O movimento da Lua',
    description: 'Vídeo educativo do Sistema Solar para crianças com o qual vão aprender mais sobre o planeta Terra e seu satélite, a Lua. Nele, as crianças vão aprender o movimento de translação, a composição da Terra ou como se alternam os dias e as noites. Este vídeo pertence a uma coleção sobre o Sistema Solar para crianças, onde os pequenos farão uma viagem para explorar todas as curiosidades dos planetas. É ideal para reforçar a matéria de Geografia ou Ciências da Educação Fundamental.',
    image: 'https://i.ytimg.com/vi/ivUk9khL6Ks/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCtGQgMCigKMpXd5-CFfOpDQKCA7A',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Luna_PT.mp4',
  },
  {
    id: 153,
    title: 'Vídeo - Aula Calendários ',
    description: 'Nesse vídeo, os alunos terão a oportunidade de associar os movimentos cíclicos da Lua e da Terra a períodos de tempo regulares e compreender a importância desse conhecimento para a construção de calendários em diferentes culturas.',
    image: 'https://i.ytimg.com/vi/TKAbwyElk2E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDXum2FGUqJgz6S1O5LCkIcrklAxA',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF04CI11/ocalendarioaoredordomundo.mp4',
  },
  {
    id: 154,
    title: 'Quiz  -  Calendários',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre os movimentos cíclicos da Lua e da Terra a períodos de tempo regulares e compreender a importância desse conhecimento para a construção de calendários em diferentes culturas.',
    image: 'https://i.ytimg.com/vi/TKAbwyElk2E/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDXum2FGUqJgz6S1O5LCkIcrklAxA',
    AssetsLink: 'https://quizgameprototype.netlify.app/EduMania/?EduMania;EduMania-Ci%C3%AAncias9119;EF04CI11',
  },

  //  ------------------------------------------------------------ Ciências - EF05CI01 -------------------------------------------------------------------------------------------//

  {
    id: 155,
    title: 'Vídeo  - Propriedades Físicas dos Materiais ',
    description: 'Nesse vídeo, os alunos terão a oportunidade de explorar fenômenos da vida cotidiana que evidenciem propriedades físicas dos materiais, como densidade, condutibilidade térmica e elétrica, respostas a forças magnéticas, solubilidade e respostas a forças mecânicas, como dureza e elasticidade, entre outras.',
    image: 'https://i.ytimg.com/vi/WXAst3FkBSE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBUNShlxjYJCEMprz_bMrTXyypAuw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF05CI01/densidadedosobjetos.mp4',
  },

  {

    id: 156,
    title: 'Vídeo - Magnetismo ',
    description: 'Vídeo educativo para crianças no qual elas aprenderão sobre o magnetismo e os ímãs. O magnetismo é a propriedade que alguns objetos têm de atrair elementos metálicos, como o ferro. A matéria é carregada eletricamente e normalmente esta carga é eletricamente neutra, ou seja, o número de cargas negativas e positivas é equilibrado. No entanto, em certos materiais não é assim, e este desequilíbrio causa atração ou repulsão entre eles. Os ímãs podem ser tanto naturais quanto artificiais, e uma de suas principais características é a capacidade de atrair objetos feitos de ferro e de outros materiais metálicos, conhecida como magnetismo. Os ímãs naturais são encontrados na natureza. Um exemplo é a magnetita. Os ímãs artificiais são feitos pelo homem a partir de diferentes metais, como ferro ou aço. Os ímãs têm dois polos, que normalmente estão localizados em suas extremidades, e estes geram um campo magnético. Eles são conhecidos como  polos norte e sul.  Se aproximarmos os polos opostos de dois imãs, eles se atraem. Ou seja, se juntarmos o polo norte de um ímã com o polo sul de outro, eles se atraem. Por outro lado, se aproximarmos dois polos iguais, eles se repelem. Os dispositivos de armazenamento de informação digital, como discos rígidos e cartões de memória, usam propriedades magnéticas para armazenar dados. Telas, alto-falantes e outros objetos eletrônicos também têm ímãs dentro deles.',
    image: 'https://i.ytimg.com/vi/Ki3S4t8QB2w/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCpyo0ONTWVF-x2ijb6PLzAEYrLFA',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/MAGNETISM_PT.mp4',
  },
  {
    id: 157,
    title: 'QUIZ - Propriedades Físicas dos MateriaisPropriedades Físicas dos Materiais',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre os fenômenos da vida cotidiana que evidenciem propriedades físicas dos materiais, como densidade, condutibilidade térmica e elétrica, respostas a forças magnéticas, solubilidade e respostas a forças mecânicas, como dureza e elasticidade, entre outras.',
    image: 'https://i.ytimg.com/vi/WXAst3FkBSE/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBUNShlxjYJCEMprz_bMrTXyypAuw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF05CI01_2',
  },
  //  ------------------------------------------------------------ Ciências -EF05CI02 e EF05CI04 -------------------------------------------------------------------------------------------//

  {

    id: 158,
    title: 'Vídeo - Ciclo da água ',
    description: 'Vídeo educativo para crianças, com o qual vão aprender o ciclo da água e quais são as etapas de: evaporação, condensação e precipitação. Descubra qual é a função do sol, como as nuvens se formam, por que se formam as gotas de chuva e muito mais. Este vídeo faz parte de uma coleção de ciências para crianças, no qual vão aprender conceitos relacionados e muito vocabulário. Vídeo perfeito para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/KZTRy6KL7_c/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCZZ9uIf9Sy4OBBZV367zOlVeHIzw',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Ciclo_Agua_PT.mp4',
  },
  {
    id: 159,
    title: ' Quiz - Ciclo da água',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre os conhecimentos sobre as mudanças de estado físico da água, a fim de explicar o ciclo hidrológico e analisar suas implicações na agricultura, no clima, na geração de energia elétrica, no provimento de água potável e no equilíbrio dos ecossistemas regionais ou locais.',
    image: 'https://i.ytimg.com/vi/KZTRy6KL7_c/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCZZ9uIf9Sy4OBBZV367zOlVeHIzw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF05CI02',
  },
  {
    id: 160,
    title: 'Vídeo - Economizar água',
    description: 'Vídeo educativo para crianças com o qual vão aprender a economizar água. A água é um recurso imprescindível para a vida no planeta Terra. É por isso que temos que aprender a não desperdiçá-la. Você sabia que só 0,025% da água do planeta é potável? Neste vídeo, as crianças vão aprender a economizar água com pequenas dicas: ver se as torneiras estão fechadas, não usar a privada como lata de lixo, e muito mais. É um ótimo material para o Ensino Fundamental.',
    image: 'https://i.ytimg.com/vi/ClcluFPSi0Y/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDfdmpLxoW2xq357ntUoUb_ImBrUg',
    AssetsLink: 'https://iptv.smileandlearn.com/video/PT/Ahorro_Agua_PT.mp4',
  },

  {

    id: 161,
    title: ' Quiz - Economizar água ',
    description: 'O quiz economizar água é um questionário interativo para testar a aprendizagem teórica sobre o vídeo relacionado com este quiz. As perguntas estão direcionadas às informações e aos conceitos expostos no vídeo.',
    image: 'https://i.ytimg.com/vi/ClcluFPSi0Y/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDfdmpLxoW2xq357ntUoUb_ImBrUg',
    AssetsLink: 'https://iptv.smileandlearn.com/game/WATERSAVING_QUIZ/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==',
  },
  {
    id: 162,
    title: ' Vídeo - Consumo consciente',
    description: 'Nesse vídeo, os alunos terão a oportunidade de identificar os principais usos da água e de outros materiais nas atividades cotidianas, com o objetivo de discutir e propor formas sustentáveis de utilização desses recursos. O vídeo busca conscientizar os alunos sobre a importância da água e de outros materiais em nosso dia a dia, destacando a necessidade de utilizá-los de maneira responsável e sustentável.',
    image: 'https://i.ytimg.com/vi/tqr9ww9TTY8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDMR0Mdx_KDv0dvdeH-rHaV7rBvQw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF05CI04/consumoconsciente.mp4',
  },
  {
    id: 163,
    title: ' Vídeo- Ciclo hidrológico ',
    description: 'Nesse vídeo, os alunos terão a oportunidade de observar e explorar os conhecimentos sobre as mudanças de estado físico da água, a fim de explicar o ciclo hidrológico e analisar suas implicações na agricultura, no clima, na geração de energia elétrica, no provimento de água potável e no equilíbrio dos ecossistemas regionais ou locais.',
    image: 'https://i.ytimg.com/vi/KZTRy6KL7_c/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCZZ9uIf9Sy4OBBZV367zOlVeHIzw',
    AssetsLink: 'https://cdn.ip.tv/metaverso/pt-br/video/ciencias/EF05CI02/ciclohidrologico.mp4',
  },
  {
    id: 164,
    title: '  Quiz - Consumo consciente',
    description: 'Nesse quiz, os alunos terão a oportunidade de reforçar a aprendizagem teórica sobre os principais usos da água e de outros materiais nas atividades cotidianas, com o objetivo de discutir e propor formas sustentáveis de utilização desses recursos.',
    image: 'https://i.ytimg.com/vi/tqr9ww9TTY8/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDMR0Mdx_KDv0dvdeH-rHaV7rBvQw',
    AssetsLink: 'https://quizgameprototype.netlify.app/edumania/?EduMania;EduMania-Ci%C3%AAncias9119;EF05CI04',
  },

  //  ------------------------------------------------------------ Ciências - EF05CI03 -------------------------------------------------------------------------------------------//

  {
    id: 165,
    title: '',
    description: '  ',
    image: '',
    AssetsLink: '',
  },
  {
    id: 166,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
  {
    id: 167,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
  {
    id: 168,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
  {
    id: 169,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
  {
    id: 170,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
  {
    id: 171,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
  {
    id: 172,
    title: ' ',
    description: '',
    image: '',
    AssetsLink: '',
  },
];

export default cardData
console.log(cardData);