import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom"; // Import the Routes component
import AssetCatalog from "./../Pages/AssetCatalog/AssetCatalog"

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route element={<HomePage />} path="/" />*/}
                <Route element={<AssetCatalog />} path="/" />
                <Route element={<AssetCatalog />} path="/Catalogo" />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
